import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import App from './App_feedback';
// import reportWebVitals from './reportWebVitals';



// createGlobalStyle нужен для создания глобальных стилей
import styled, { createGlobalStyle } from 'styled-components';

// получаем значение для целевой платформы
// import { sberBox } from '@sberdevices/plasma-tokens/typo';
// import { sberPortal } from '@sberdevices/plasma-tokens/typo';
// import { touch } from '@sberdevices/plasma-tokens/typo';
import { mobile } from '@sberdevices/plasma-tokens/typo';
// получаем стилевые объекты для нашего интерфейса
import { body1, headline2 } from '@sberdevices/plasma-tokens';

// получаем тему персонажа
// import { darkJoy, lightSber } from '@sberdevices/plasma-tokens/themes';
import { 
  lightSber, 
  darkSber,
  darkJoy
} from '@sberdevices/plasma-tokens/themes';
// import { darkSber } from '@sberdevices/plasma-tokens/themes';
// получаем цвета для нашего интерфейса
import { text, background, gradient } from '@sberdevices/plasma-tokens';

// import './antd.css'
import './antd.dark.css'

// padding: 30px 20px 30px 60px; 
// padding: 30px 20px 30px 20px; 
const AppStyled = styled.div`
    ${body1}
    
    `;
    // background: url(/valli_main_bg_sketch.jpg);
    // min-height: 100%;
    // background-position: cover;

// создаем react-компонент c глобальными стилями типографики
const TypoScale = createGlobalStyle(mobile);
// const TypoScale = createGlobalStyle(sberPortal);

const DocStylesPurPur = createGlobalStyle`
  html {
    background: radial-gradient(circle farthest-side at 80% 30%, #300e5a, black 80%, black);
  }
`;
const DocStylesWhiteBox = createGlobalStyle`
  html {
    background: #e2e2ec url('/back2.jpg') center top no-repeat !important; 
  }
  :root {
    --plasma-colors-button-accent: #0bd818 !important;
  }
`;
const DocStylesGreenClub = createGlobalStyle`
  html {
    color: #ebebeb !important;
    background: #030303 url('/bg_green_club.jpg') center top no-repeat fixed !important; 
    background-size: cover !important;
  }
  h1, h2, h3, div, p {
    font-weight: 100 !important;
    letter-spacing: normal;
  }
  // :root {
  //   --plasma-colors-button-accent: #0bd818 !important;
  // }
`;

// создаем react-компонент для подложки
const DocStyles = createGlobalStyle`
    /* stylelint-disable-next-line selector-nested-pattern */
    html {
        font-family: var(--plasma-typo-body2-font-family);
        color: ${text};
        /*
        background-color: ${background};
        background-image: ${gradient};
        */
        /** необходимо залить градиентом всю подложку */
        min-height: 100vh;
        background-size: cover !important;

      }
      
      
      `;
      // filter: invert(1);
      // background: #d5dae1 url('/sberlight_bg_2.jpg') center top no-repeat !important; 
      // background: #d5dae1 url('/sberlight_bg_2.jpg') calc(50% + 150px) top no-repeat !important; 
      // // html, body { min-height: 100vh; } 
    // body { 
    //     background: #011a17 url('/sbernight_opt.jpg') center bottom no-repeat; 
    //     background-size: cover; 
    // }

// создаем react-компонент для персонажа
// const Theme = createGlobalStyle(lightSber);
const Theme = createGlobalStyle(darkSber);
// const Theme = createGlobalStyle(darkJoy);

ReactDOM.render(
  <React.StrictMode>

    <AppStyled>
      {/* Используем глобальные react-компоненты один раз */}
      <TypoScale />
      <DocStyles />
      {/* <DocStylesWhiteBox /> */}
      <DocStylesGreenClub />
      {/* <DocStylesPurPur /> */}
      <Theme />

      <App />

    </AppStyled>

  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
