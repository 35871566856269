import { Col, Row } from "antd";
import axios from "axios";
import QRCode from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import './ThesisPage.css'


// import './js/three.js'
// import './js/dat-gui.js'
// import './js/gsap.js'
// import {Sketch} from './js/sketch6.js'
// import './js/demo6.js'

import { gsap } from "gsap";

// {
//     "file": "screen.jpg",
//     "timestamp": 1636448782,
//     "text": "Поздравляем с победой в социально важном конкурсе ",
//     "speaker": "Награждение победителей детского соревнования",
//     "text_en": "Congratulations on winning the socially important competition",
//     "speaker_en": "Awarding the winners of the children's competition"
// }

export function img_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "image/jpeg"}) )
}

export function microTypographer(text, withHtmlWrapper = true) {
    // let p = ['у','в','на','по','до','к','с','и','за'];

    // p.forEach(x => {
    //     let pattern = ' ' + x + ' ';
    //     let 
    //     text.replace(/ у /g,' e')
    // })

    let t = text.replace(/ (у|в|о|на|по|во|до|к|с|и|за|для|vs|из|под|его|её) /gi, ' $1&nbsp;');
    t = t.replace(/ ([^ ]{1,5})$/, '&nbsp;$1');
    
    if (!withHtmlWrapper) return t

    return <span dangerouslySetInnerHTML={{ __html:t }}/>
}


export default function ThesisPage({lang='ru', light=true}) {

    const [text, set_text] = useState('')
    const [author, set_author] = useState('')
    const [timestamp, set_timestamp] = useState(0)
    const [timestamp_old, set_timestamp_old] = useState(0)
    const [filename, set_filename] = useState('screen.jpg')
    const [filename_old, set_filename_old] = useState('screen.jpg')

    const history = useHistory()

    const boxRef = useRef();
    const boxRef_txt = useRef();
    const boxRef_oldcover = useRef();

    const update = () => {


    }

    const [cover_img_url, set_cover_img_url] = useState('')
    const [cover_img_url_old, set_cover_img_url_old] = useState('')

    const load = ( reset = false) => {
        axios.get('http://45.89.225.221:8110/imgcloud/screen.json?r=' + Math.random() ).then( res => {
            
            if (timestamp != res.data.timestamp || reset )  {
                console.log(res.data, timestamp, res.data.timestamp, timestamp != res.data.timestamp)
                if (lang == 'ru') {
                    set_text( res.data.text )
                    set_author( res.data.speaker )
                } else {
                    set_text( res.data.text_en )
                    set_author( res.data.speaker_en )
                }
                set_timestamp_old( timestamp )
                set_filename_old( filename_old )

                set_timestamp( res.data.timestamp )
                set_filename( res.data.file )


                if (res.data.file) {
                    fetch('http://45.89.225.221:8110/imgcloud/' + res.data.file + '?ts=' + res.data.timestamp)
                    // fetch('http://45.89.225.221:8110/imgcloud/qr/1636448782/screen.jpg?ts=1636448782')
                        .then( t => t.blob().then( b => {
                            set_cover_img_url_old( cover_img_url ) 
                            set_cover_img_url( img_blob_to_url(b) ) 

                            gsap.set(boxRef.current, { opacity:0, scale:0.9});
                            gsap.to(boxRef.current, { opacity:1, scale:1, duration: 1 });
                            
                            gsap.set(boxRef_oldcover.current, { opacity:1, scale:1});
                            gsap.to(boxRef_oldcover.current, { opacity:0, scale:1.1, duration: 1 });
                
                            gsap.set(boxRef_txt.current, { opacity:0, scale:0.9});
                            gsap.to(boxRef_txt.current, { opacity:1, scale:1, duration: 1 });
                        })) 
                }
    
            } else {
                console.log('--')
            }

		})
    }

    useEffect(()=>{
        let iid =  setInterval(() => {
            load()    
        }, 5000);
        load()
        return () => {
            clearInterval(iid)
        }
    },[lang, timestamp])



    return (
        <div style={{padding:'10vh'}} onClick={()=>load(true)}>



        {/* <div id="content" class="content">			
			<div id="slider" data-images='["http://45.89.225.221:8110/imgcloud/screen.jpg","http://45.89.225.221:8110/part1/01-02.jpg"]' data-disp="http://45.89.225.221:8110/imgcloud/screen.jpg">
			</div>
		</div> */}


            { light &&
            <style>

                {`
                body { 
                    background: #000 url(/back_aij_light.jpg) center center no-repeat;
                    background-size: cover;
                    color: #000;
                }
                `}
            </style>
            }

                    {/* {text}
                    {author}
                    {timestamp} */}
                    <img className="logo_sbervox open" src={ light ? "http://sbervox.vispstudio.ru/sbervox_outline_black.svg" : "http://sbervox.vispstudio.ru/sbervox_outline.svg" } />
                    {/* <div className="logodesc open">
                        Суммаризированный тезис, 
                        <br/>выделенный и визуализированный 
                        <br/>искусственным интеллектом
                    </div>	 */}
                    <div className="desc open" ref={boxRef_txt}>
                        {/* {text} */}
                        {microTypographer(text)}
                        <p className="author">{author}</p>
                    </div>	
                    {/* <img className="logo_sberai open" src="http://sberai.vispstudio.ru/sberai_logo.svg" /> */}
                    <div className="powerby open">
                        powered by 
                        <br/>
                        <img style={{width:'20vh', marginTop: '10px'}} src={ light ? "http://sberai.vispstudio.ru/sberai_logo_black.svg" : "http://sberai.vispstudio.ru/sberai_logo.svg"}/>
                    </div>
                
                    {/* <img className="cover" ref={boxRef} 
                            src={'http://45.89.225.221:8110/imgcloud/' + filename + '?ts=' + timestamp } /> */}
                    <img className="cover" ref={boxRef} 
                            src={cover_img_url} />
                    <img className="cover oldcover" style={{zIndex:2}} ref={boxRef_oldcover} 
                            src={cover_img_url_old } />
                    {/* <img className="cover oldcover" style={{zIndex:2}} ref={boxRef_oldcover} 
                            src={'http://45.89.225.221:8110/imgcloud/' + filename_old + '?ts=' + timestamp_old } /> */}
                    {/* <img className="qr_code open" width={100} src="http://sbervox.vispstudio.ru/qr-code3.png" /> */}

                    <div onClick={()=>{history.push( '/' + lang + '/t/' + timestamp + (light ? '' : '/d'))}} className="qr_code open" style={{background: '#fff', padding:'1% 1% 0.8% 1%', borderRadius:'5px'}}>
                    {/* <div className="open" style={{background: '#fff', padding:'2%', marginRight: '-5vh', width: '15vh', position:'absolute', right:0, top:'20vh'}}> */}
                        {/* votecode: {votecode} */}
                        {/* {"http://sbervox.vispstudio.ru/#/t/" + timestamp} */}
                        <QRCode 
                            value={"http://sbervox.vispstudio.ru/#/"+lang+"/t/" + timestamp + (light ? '' : '/d')}
                            renderAs="svg"
                            width={'100%'}
                            height={'100%'}
                            level="L" 
                            // imageSettings={ {width: '20%', test:"test"} }
                        />
                    </div>

        </div>
    )
    // return (
    //     <div style={{padding:'10vh'}}>
    //         <Row onClick={()=>load()} gutter={40}>
    //             <Col span={12}>
    //                 {/* {text}
    //                 {author}
    //                 {timestamp} */}
    //                 <img className="logo_sbervox open" src="http://sbervox.vispstudio.ru/sbervox_outline.svg" />
    //                 <div className="logodesc open">
    //                     Суммаризированный тезис, 
    //                     <br/>выделенный и визуализированный 
    //                     <br/>искусственным интеллектом
    //                 </div>	
    //                 <div className="desc open">
    //                     {text}
    //                     <p className="author">{author}</p>
    //                 </div>	
    //                 {/* <img className="logo_sberai open" src="http://sberai.vispstudio.ru/sberai_logo.svg" /> */}
    //                 <div className="powerby open">
    //                     powered by 
    //                     <br/>
    //                     <img style={{width:'20vh', marginTop: '10px'}} src="http://sberai.vispstudio.ru/sberai_logo.svg"/>
    //                 </div>
    //             </Col>
    //             <Col span={12}>
    //                 <img style={{width:'100%'}} className="cover open" src={'http://45.89.225.221:8110/imgcloud/' + filename + '?ts=' + timestamp} />
    //                 {/* <img className="qr_code open" width={100} src="http://sbervox.vispstudio.ru/qr-code3.png" /> */}

    //                 <div className="open" style={{background: '#fff', padding:'2%', marginRight: '-5vh', width: '15vh', position:'absolute', right:0, top:'20vh'}}>
    //                     {/* votecode: {votecode} */}
    //                     {/* {"http://sbervox.vispstudio.ru/#/t/" + timestamp} */}
    //                     <QRCode 
    //                         value={"http://sbervox.vispstudio.ru/#/t/" + timestamp}
    //                         renderAs="svg"
    //                         width={'100%'}
    //                         height={'100%'}
    //                         level="L" 
    //                         // imageSettings={ {width: '20%', test:"test"} }
    //                     />
    //                 </div>
    //             </Col>
    //         </Row>
    //     </div>
    // )
}