import { Button, Container } from "@sberdevices/plasma-ui";
import { Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../api/api";
import BaseLayout from "../elems/BaseLayout";

export default function PresListPage() {
    const history = useHistory();
    const [preslist, set_preslist] = useState([]);

	useEffect(()=> {
        api.get_pres_list().then( res => {
            console.log('preslist', res)
            set_preslist(res) 
        } )
	}, [])

    return (
        <BaseLayout width={750} logo={false} topcenter=" "topleft={' '} topright={ ' '}>

            <h2>Список презентаций</h2>

            {/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( preslist, true, "\t" ) }</pre></small> */}

            {preslist.map((x,i)=> (

                <div key={x.votecode} style={{display:'flex', justifyContent: 'space-between', alignItems:"center"}}>
                    <h3 style={{width:'100%'}}>
                        {x.title['ru']}
                        <br/>
                        <small className="en" style={{opacity:0.4}}>{x.title['en']}</small>
                    </h3>
                    <div style={{padding:20, opacity:0.4}}>
                        {x.votecode}
                    </div>
                    {/* <h3 className="en" style={{opacity:0.4}}>{x.title['en']}</h3> */}
                    <Button view="primary" onClick={()=>{ history.push('/pres/' + x.id + '/slides') }}>
                        {/* {x.title['ru']} / {x.title['en']} ({x.votecode}) */}
                        play
                    </Button>
                    &nbsp;
                    <Button view="secondary" onClick={()=>{ history.push('/moder/' + x.id ) }}>
                        moderate
                    </Button>
                </div>
                
            ))}

            {/* <h2>Советы</h2> */}
            <br/>
            <br/>
            <br/>
            <p style={{color: '#777', textAlign:'center'}}>
                Для масштабирования слайда используйте сочетния <b>Ctrl+</b> и <b>Ctrl-</b> (для mac <b>⌘+</b> и <b>⌘-</b>)
            </p>
            <style>
                {`b { color: #bbb; }`}
            </style>

        </BaseLayout>
    )
}