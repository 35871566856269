import { Col, Row } from "antd";
import SlideLayout from "../../elems/SlideLayout";
import VotePresQR from "../../elems/VotePresQR";

export default function IntroSlide({votecode}) {
	return (
		<SlideLayout>
				{/* <br /> */}
				{/* <br /> */}
				{/* <img style={{maxWidth:'200px', margin:'10px auto 20px'}} src="/sbervox_logo.png" /> */}
				<img style={{maxWidth:'160px', margin:'20px auto -45px'}} src="/sbervox2_logo.png" />
				{/* <img style={{maxWidth:'160px', margin:'20px auto -45px'}} src="/sbervox_white.png" /> */}
				{/* ssdd */}

				<Row gutter={30}>
					<Col span="12" style={{textAlign:'left'}}>
						<p>
						</p>
						<h1 style={{lineHeight:1.2}}>
							Интерактивные опросы
							<br/><span className="en">Interactive polls</span>
						</h1>
						<br />
						<p style={{fontSize:'23px', lineHeight:1.3}}>
							Для участия в голосовании <br />
							отсканируйте QR-код 
							<br/><span className="en">
								To vote scan the QR code <br />
								
							</span>
							<br />
							или зайдите на <b>sbervox.vispstudio.ru</b> <br />
							и укажите код <b>{votecode}</b>
							<br/><span className="en">
								or go to <b>sbervox.vispstudio.ru</b><br />
								and enter the code <b>{votecode}</b><br />
							</span>
						</p>
						<br />
					</Col>
					<Col span="12">
						{/* <img width="100%" src="/qr-code3.png" /> */}
						<VotePresQR votecode={votecode} />
					</Col>
				</Row>
		</SlideLayout>
	)
}
