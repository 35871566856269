

import axios from "axios"
import {prepare_pres_slides, prepare_vote_slide} from "./prepare/prepare_slides";

import demo_vote_presentation from './demodata/vote_presentation.json'
import demo_get_presentation_list from './demodata/get_presentation_list.json'
import demo_get_presentation from './demodata/get_presentation.json'

import { WarningOutlined, PlusSquareOutlined, FireOutlined } from '@ant-design/icons';

import { notification } from 'antd';

// https://45.89.225.221:8105/vox/api/v1/vote_presentation?votecode=5555
// const api_url = 'http://45.89.225.221:8105/vox/api/v1/';
const api_url = 'http://45.89.225.221:8108/vox/api/v1/';
const demo_mode = false;
// const demo_mode = true;
;

// let ACCESS_TOKEN = null;
// let HISTORY = null;

// axios.defaults.withCredentials = true


let API_PARAMS = {
	unauth_redirect_to: '/',
	history: null,
	access_token: null,
}

const API_PARAM_MIXIN = {
	
}

// axios.interceptors.response.use(
//     response => {
//         return response
//     },
//     error => {
//         if (!error.response) {
//             console.log("Please check your internet connection.");
//         }

//         return Promise.reject(error)
//     }
// )


const API_EVENT_MIXIN = {
	_eventHandels: [],
	addEventListener(eventName, handel) {
		this._eventHandels.push({
			eventName: eventName,
			handel: handel,
		})
	},
	removeEventListener(eventName, handel) {
		this._eventHandels = this._eventHandels.filter(x => x.eventName !== eventName || x.handel !== handel);
	},
	dispatchEvent(eventName, event) {
		this._eventHandels.filter(x => x.eventName === eventName).forEach( e => e.handel(event) )
	},
}


const _onErr = (err) => {


	// if (err.request && err.request.status == 401 ) { // не авторизован
	// 	// window.open('/login')
	// 	//document.location.href = ('/login')
	// 	// this.redirect('/login')
	// }
	// notification.open({
	// 	duration: 0,
	// 	message: err.message,
	// 	description: err.config?.url,
	// 	onClick: () => {
	// 		// window.open('https://45.89.225.221:9100/get_meetings', '_blank').focus();
	// 		window.open(api.get_test_url(), '_blank').focus();
	// 	},
	// 	icon: <WarningOutlined style={{ color: 'red' }} />
	// });


	// catch(error => {
    //     if (!error.response) {
    //         // network error
    //         this.errorStatus = 'Error: Network Error';
    //     } else {
    //         this.errorStatus = error.response.data.message;
    //     }
    // })
	if (!err?.response) {
		notification.open({
			// duration: 0,
			message: 'Ошибка',
			description: <> 
				{'Нет связи с сервером'}
				<br/> 
				{err.message}
			</>,
			placement: 'bottomRight',
			// description: 'NET::ERR_CERT_AUTHORITY_INVALID',
			// description: err.data.result.error,
			// description: err.response.data.result.error,
			// description: <small style={{lineHeight:1, fontSize:'10px'}}><pre>{JSON.stringify(err, true, "\t")}</pre></small>,
			icon: <WarningOutlined style={{ color: 'red' }} />
		});
	} else {

		if (err?.response?.statusText === 'UNAUTHORIZED') {
			API_EVENT_MIXIN.dispatchEvent('unauth', err);
		}
		// console.log('err', typeof err , err.response, err.request, err, JSON.stringify(err))
	
		// for (var key in err) {
		// 	console.log('--', key)
		// 	for (var k in err[key]) {
		// 		console.log('-- -- ', k, '=', err[key][k])
		// 	}  
		// }
		
		notification.open({
			// duration: 0,
			message: err.response?.data?.result?.error || 'Ошибка',
			description: <> 
				{err.response?.status} {err?.response?.statusText || 'net::ERR_CERT_AUTHORITY_INVALID'}
				<br/> 
				{err.message}
			</>,
			placement: 'bottomRight',
			// description: 'NET::ERR_CERT_AUTHORITY_INVALID',
			// description: err.data.result.error,
			// description: err.response.data.result.error,
			// description: <small style={{lineHeight:1, fontSize:'10px'}}><pre>{JSON.stringify(err, true, "\t")}</pre></small>,
			icon: <WarningOutlined style={{ color: 'red' }} />
		});
	}
	
	return Promise.reject( err )
}

const isAuth = () => {
	return API_PARAMS.access_token ? true : false
}

const authBlock = (err, method) => {
	console.log('authBlock')
	const defErr = {
		response: {
			status: '401',
			statusText: 'UNAUTHORIZED' 
		}, 
		message: 'Для запроса нужна авторизация'
	}

	return _onErr(err || defErr)
}

const API = {

	...API_EVENT_MIXIN,

	init(config) {
		API_PARAMS = {
			API_PARAMS,
			...config
		}
	},

	auth(login, pass) {
		if (demo_mode) {
			API_PARAMS.access_token = 'demo_token';
			return Promise.resolve( API_PARAMS.access_token );
		}

		return axios.post( api_url + 'auth' ).then(res => {
			API_PARAMS.access_token = res.data?.result?.access_token || null;

			axios.defaults.headers.common = {
				'Authorization': 'Bearer ' + API_PARAMS.access_token
			}

			return API_PARAMS.access_token
		}, _onErr )
	},

	get_test_url() {
		return api_url + 'auth';
	},

	//
	get_pres_list() {
		if (!isAuth()) return authBlock()

		const prepare = (data) => {
			return (data?.result?.presentations || []).map( x => ({
				id: x.presentation_id,
				title: {
					ru: x.title,
					en: x.title_eng
				},
				votecode: x.votecode,
			}))
		}

		if (demo_mode) return Promise.resolve( prepare( demo_get_presentation_list ) );

		return axios.get( api_url + 'presentationlist' ).then( res => prepare(res.data), _onErr )
	},
	
	get_pres_slides(pres_id) {
		if (!isAuth()) return authBlock()

		if (demo_mode) return Promise.resolve( prepare_pres_slides( demo_get_presentation.result ) );

		return axios.get( api_url + 'presentation', {
			params: {
				pres_id: pres_id
			}
		} ).then( res => prepare_pres_slides(res.data.result), _onErr )
	},


	//
	set_focus( pres_id = 1, slide_id = 1 ) {
		if (!isAuth()) return authBlock()

		if (demo_mode) return Promise.resolve({});

		return axios.post( api_url + 'set_focus', {}, {
			params: {
				pres_id: pres_id,
				slide_id: slide_id
			}
		}).then( res => {}, _onErr)
	},

	//
	get_aggregation( slide_id = 1 ) {
		if (!isAuth()) return authBlock()

		if (demo_mode) return Promise.resolve([])

		return axios.get( api_url + 'get_aggregation', {
			params: {
				slide_id: slide_id
			}
		}).then( res => res.data.result, _onErr)
	},


	///////////////////////////

	//
	get_vote_slide(votecode = 5555) {
		if (demo_mode) return Promise.resolve( prepare_vote_slide( demo_vote_presentation.result ) );

		return axios.get( api_url + 'vote_presentation', {
			params: {
				votecode: votecode
			}
		}).then(res => prepare_vote_slide( res.data.result ), _onErr )
	},

	//
	get_focus(votecode = 5555) {
		if (demo_mode) return Promise.resolve( 1 );

		return axios.get( api_url + 'get_focus', {
			params: {
				votecode: votecode
			}
		}).then( res => {
			return res.data.result.slide
		}, _onErr )
	},

	send_vote(votecode = 5555, pres_id = 1, slide_id = 1, choice_arr = [], lang = 'ru') {
		console.log('send_vote', choice_arr )
		return axios.post( api_url + 'vote_presentation', choice_arr.map((x,i) => ({
			presentation_id: pres_id,
			slide_id: slide_id,
			// option_id: x, // ?
			// choice_option_id: x, // ?
			multi_choice_option_id: x, // ?
			ranking_option_id: x,
			rank: i,
			language: lang === 'ru' ? 0 : 1,
		})), {
			params: {
				votecode: votecode,
				pres_id: pres_id,
			}
		} ).then(res => {
			notification.open({
				// duration: 0,
				message: lang == 'ru' ? 'Ваш голос учтен, спасибо!' : 'Thanks for your vote!',
				description: res.data.result.answer,
				// description: <small style={{lineHeight:1, fontSize:'10px'}}><pre>{JSON.stringify(res, true, "\t")}</pre></small>,
				icon: <PlusSquareOutlined style={{ color: 'green' }} />
			});
			return res.data.result.answer

		}, _onErr );
		// }, err => {
		// 	notification.open({
		// 		// duration: 0,
		// 		message: 'Ошибка',
		// 		// description: 'NET::ERR_CERT_AUTHORITY_INVALID',
		// 		// description: <small style={{lineHeight:1, fontSize:'10px'}}><pre>{JSON.stringify(err, true, "\t")}</pre></small>,
		// 		// icon: <PlusSquareOutlined style={{ color: 'green' }} />
		// 		message: err.response?.data?.result?.error,
		// 		description: <> 
		// 			{err.response.status} {err.response.statusText}
		// 			<br/> 
		// 			{err.message}
		// 		</>,
		// 		icon: <WarningOutlined style={{ color: 'red' }} />
		// 	});
		// 	return err
		// })
	},




	// get_wall_auth_token() {
	// 	return axios.post( api_url + 'auth' ).then(res => {
	// 		// API_PARAMS.access_token = res.data?.result?.access_token || null;

	// 		// axios.defaults.headers.common = {
	// 		// 	'Authorization': 'Bearer ' + API_PARAMS.access_token
	// 		// }

	// 		// return API_PARAMS.access_token
	// 		return res.data?.result?.access_token || null
	// 	}, _onErr )
	// },
	get_wall_aggregation(slide_id = 1, moderated = 1) {
			return axios.get( api_url + 'get_aggregation', {
				params: {
					slide_id: slide_id,
					moderated: moderated, 
				},
			}).then( res => {
				return res?.data?.result
			}, _onErr )
	},
	get_wall_moderate(pres_id, slide_id) {
			return axios.get( api_url + 'moderate_presentation', {
				params: {
					pres_id: pres_id,
					slide_id: slide_id,
				},
			}).then( res => {
				return res?.data?.result
			}, _onErr )
	},
	// set_wall_moderate(pres_id = 1, answer_id = 1) {
	set_wall_moderate(comment) {
			return axios.post( api_url + 'moderate_presentation', [
				// {
				// 	"presentation_id":pres_id,
				// 	"answer_id": answer_id,
				// 	"moderated":1,
				// 	"comment":"Лол кек поменяно",
				// 	"comment_eng":"Lol kek ch0nged"
				// }
				comment
			], {}).then( res => {
				return res?.data?.result
			}, _onErr )
	},

	// send_wall_comment(comment = "Don't stop!", author = "da cat", lang = 'ru') {
	// send_wall_comment(comment, votecode = 5555, lang="ru") {
	send_wall_comment(votecode = 5555, pres_id = 1, slide_id = 1, comment = 'Dont panic!', author='', lang = 'ru') {
			console.log('send_wall_comment', comment )
			return axios.post( api_url + 'vote_presentation', 
			// choice_arr.map((x,i) => (
			// 	{
			// 		presentation_id: pres_id,
			// 		slide_id: slide_id,
			// 		// multi_choice_option_id: x,
			// 		ranking_option_id: x,
			// 		rank: i,
			// 		language: lang === 'ru' ? 0 : 1,
			// 	}
			// ))
			[
				{
					slide_id: slide_id,
					presentation_id: pres_id,
					comment: comment,
					author: author,
					language: lang === 'ru' ? 0 : 1,
				}
				// comment
			]
			, {
				params: {
					votecode: votecode
				}
			} ).then(res => {
				notification.open({
					// duration: 0,
					// message: lang == 'ru' ? 'Ваш голос учтен, спасибо!' : 'Thanks for your vote!',
					message: lang == 'ru' ? 'Спасибо! Ваше сообщение отправлено' : 'Thanks! Your message has been sent',
					description: res.data.result.answer,
					// description: <small style={{lineHeight:1, fontSize:'10px'}}><pre>{JSON.stringify(res, true, "\t")}</pre></small>,
					icon: <PlusSquareOutlined style={{ color: 'green' }} />
				});
				return res.data.result.answer
	
			}, _onErr );
	}

}

export default API