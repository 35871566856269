
import QRCode from "qrcode.react";


export default function VotePresQR({votecode, width="100%"}) {

    // {/* 
    //     renderAs	    string ('canvas' 'svg')	    'canvas'
    //     size	        number	                    128
    //     bgColor	        string (CSS color)	        "#FFFFFF"
    //     fgColor	        string (CSS color)	        "#000000"
    //     level	        string ('L' 'M' 'Q' 'H')	'L'
    //     includeMargin	boolean	                    false
    //     imageSettings {
    //         src	    string	
    //         x	    number	none, will center
    //         y	    number	none, will center
    //         height	number	10% of size
    //         width	number	10% of size
    //         excavate	boolean	false
    //     }
    // */}
    return (
        // <div style={{background: 'url(/qr-code4.png) no-repeat center', backgroundSize:'contain', padding:'10%', width:width}}>
        <div style={{background: '#fff', padding:'5%', width:width}}>
            {/* votecode: {votecode} */}
            <QRCode 
                value={"http://sbervox.vispstudio.ru/#/v/" + votecode}
                renderAs="svg"
                // width={width}
                width={'100%'}
                height={'100%'}
                level="L" 
                imageSettings={ {width: '20%', test:"test"} }
            />
        </div>
    )
}