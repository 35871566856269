

import { Button } from "@sberdevices/plasma-ui"
import { IconHouse } from "@sberdevices/plasma-icons"
import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined } from '@ant-design/icons';
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

// import utils from './utils'




export function img_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "image/jpeg"}) )
}

export function download(data, filename = 'style_transfer.jpg', type = "image/jpeg") {
	let urlCreator = window.URL || window.webkitURL;
  
	var file = new Blob([data], {type: type});
	if (window.navigator.msSaveOrOpenBlob) // IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else { // Others
		var a = document.createElement("a"),
			url = urlCreator.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			urlCreator.revokeObjectURL(url);  
		}, 0); 
	}
  }

//   export default {
//     img_blob_to_url,
//     download
//   }


export default function ThesisSharePage({lang, light = true}) {
    // const [elena_status, setElenaStatus] = useState(-1)
    // const [timestamp, setTimestamp] = useState(0)

    const {timestamp} = useParams()

    const [text, set_text] = useState('')
    const [author, set_author] = useState('')
    // const [timestamp, set_timestamp] = useState(0)
    const [filename, set_filename] = useState('screen.jpg')

    const load = () => {
        // axios.get('http://45.89.225.221:8110/imgcloud/screen.json').then( res => {
        axios.get('http://45.89.225.221:8110/imgcloud/qr/'+timestamp+'/screen.json').then( res => {
            console.log(res.data)
            if (lang == 'ru') {
                set_text( res.data.text )
                set_author( res.data.speaker )
            } else {
                set_text( res.data.text_en )
                set_author( res.data.speaker_en )
            }
            // set_timestamp( res.data.timestamp )
            set_filename( res.data.file )
		})
    }

    useEffect(()=>{
        load()
    },[lang])


    return (
        <div>
            {light && 
                <style>
                    {`
                    body { 
                        background: #000 url(/back_aij_light.jpg) center center no-repeat;
                        background-size: cover;
                        color: #000;
                    }
                    `}
                </style>
            }

            {/* {text} */}
            {/* {timestamp} */}
            <div className={"EthicsPage "}>

                <Button className="btn-download" view={'primary'} size="l" 
                onClick={()=> fetch('http://45.89.225.221:8110/imgcloud/qr/' + timestamp + '/' + filename + '?ts=' + timestamp).then( t => t.blob().then( b => download(b, 'SberThesis.jpg') )) }
                ><DownloadOutlined style={{fontSize:"25px"}} /> &nbsp; { lang == 'ru' ? 'Скачать' : 'Download' }</Button>

        
                
                <img style={{width:'90%', maxWidth:'90vh', borderRadius: 5}} className="" src={'http://45.89.225.221:8110/imgcloud/qr/' + timestamp + '/' + filename + '?ts=' + timestamp} />


                <style>{`
                    .btn-download {
                        position:absolute;
                        top: 75vh;
                        z-index:1
                    }
                    .EthicsPage { 
                        line-height:0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100vh;
                    }
                `}</style>

            </div>
        </div>
    )
}