

export default function EmptyVotekit({lang = 'ru'}) {
	return (
		<>
				<br/>
				<br/>
				<br/>
				<br/>
				<h3 style={{textAlign:'center', margin:0}}>
					{lang == 'ru'? 'Опросов пока нет' : 'No polls yet'}
				</h3>
				<DontCloseNotes lang={lang} />
		</>
	)
}

export function DontCloseNotes({lang="ru"}) {
	return (
		<p style={{textAlign:'center'}}>
			<small style={{lineHeight:1}}>
				{ lang == 'ru' ? 
					<>Не закрывайте страницу, <br/>следующие опросы будут здесь же.</> : 
					<>Don't close the page,<br/>the next polls will be here.</> 
				}
			</small>
		</p>
	)
}
