
import { Button, Checkbox, Container, Radiobox } from "@sberdevices/plasma-ui";
// import Base from "antd/lib/typography/Base";
import { useEffect, useState } from "react";
// import { useHistory, useParams } from "react-router-dom";
import api from "../../api/api";
// import Footer from "../elems/Footer";

// import BaseLayout from "../elems/BaseLayout";


import SortPage from './SortPage'
import { Col, Row } from "antd";


// import EmptyVotekit from "./EmptyVotekit";
import { DontCloseNotes } from "./EmptyVotekit";



export default function MultiChoiceVotekit({lang="ru", slide={}}) {
	const [vals, set_vals] = useState([]);
	const [sortvals, set_sortvals] = useState([]);
	const [last_json, set_last_json] = useState('');
	// const [is_sent, set_is_sent] = useState(false);
	const [sended_ids, set_sended_ids] = useState([]);

	// const [my_answers, set_my_answers] = useState([]);

	// const vals = my_answers.find(x => x.slide_id == slide.slide_id)?.vals || []
	// const set_vals = (v) => set_my_answers([ ...my_answers])

	const lang_str = (strobj_with_langkey = []) => strobj_with_langkey[lang] || strobj_with_langkey['ru'] || ''

	const isCheck = (val) => vals.findIndex(v => v === val) > -1;
	const addCheck = (val) => set_vals([...vals, val]);
	const addOnlyOneCheck = (val) => set_vals([val]);
	const unCheck = (val) => set_vals([...vals.filter(k => k != val)]);
	const unCheckAll = () => set_vals([]);
	const toogleCheck = (val) => isCheck(val) ? unCheck(val) : addCheck(val)

	const isSendId = (id = slide.slide_id) => sended_ids.findIndex(v => v.slide_id === id) > -1;
	const addSendId = (id = slide.slide_id) => set_sended_ids([...sended_ids, {slide_id:id, answers_vals: vals}]);  
	const unSendId = (id = slide.slide_id) => {
		// set_vals( sended_ids.find(k => k.slide_id == id).answers_vals || [] )
		set_sended_ids([...sended_ids.filter(k => k.slide_id != id)]);
	}


	const sendVote = () => {
		if (isSendId()) return
		addSendId()
		api.send_vote(slide.pres_votecode, slide.pres_id, slide.slide_id, vals, lang).then(
		// api.send_vote(slide.pres_votecode, slide.pres_id, slide.slide_id, sortvals, lang).then(
			console.log, 
			() => unSendId( slide.slide_id )
		)
	}

	useEffect(()=>{

		// set_sortvals()
		if (slide.choice_id) {
			if (last_json == JSON.stringify(slide.choice_options)) {

			} else {
				// console.log('reset sortvals!!!', last_json, slide)
				// set_sortvals(slide.choice_options.map(x=>x.opt_id))
				unCheckAll()
				set_last_json(JSON.stringify(slide.choice_options))
			}
		} else {
			// set_sortvals([])
			unCheckAll()
		}

	}, [slide])

	return (
		<>
					<h2 style={{lineHeight:1, marginBottom:0}}>{ lang_str(slide.choice_title) }</h2>
					<p><small>{ lang_str(slide.choice_desc) }</small></p>
					<br />

					{slide.choice_max === 1 ? slide.choice_options.map(x => (
						<Radiobox key={x.opt_id} 
							label={ lang_str(x.opt_title) } 
							checked={ isCheck(x.opt_id) } 
							disabled={isSendId()}
							onChange={() => addOnlyOneCheck(x.opt_id)} 
						/>
					)) : slide.choice_options.map(x => (
						<Checkbox key={x.opt_id} 
							label={ lang_str(x.opt_title) } 
							checked={ isCheck(x.opt_id) }  
							disabled={isSendId()}
							onChange={() => toogleCheck(x.opt_id)} 
						/>
					))}

					{/* <Row wrap="false"> */}
						{/* <Col style={{paddingTop:'25px', width:'50px'}}>
							{Array.from({ length: slide.choice_options.length }, (v, k) => k).map((x,i)=>(<p style={{margin:'0 20px 52px'}} key={i}>{x+1}.</p>))}
						</Col> */}
						{/* <Col  flex="auto" style={{display:'flex', flexDirection: 'column', width:'70%'}}> */}
						{/* <Col> */}
							{/* <div style={{display:'flex', flexDirection: 'column'}}> */}
							{/* <SortPage items={slide.choice_options} lang={lang} onChange={items => set_sortvals(items.map(x=>x.item.opt_id))} /> */}
							{/* </div> */}
						{/* </Col> */}
					{/* </Row> */}
					{/* <SortPage items={slide.choice_options} onChange={items => set_sortvals(items.map(x=>x.item.opt_id))} /> */}


					<br/>
					{/* {JSON.stringify(vals) } */}
					{/* {JSON.stringify(sortvals) } */}
					{/* = */}
					{/* {last_json} */}
					
					<br/>

					{isSendId() ?
						<div style={{textAlign:'center'}}>
							{lang == 'ru'? 'Спасибо за ваш голос!' : 'Thanks for your vote!'}
							<br/>
							<br/>
							<DontCloseNotes lang={lang} />
						</div>
						:
						<Button view="primary" disabled={!vals.length} onClick={sendVote}>
							{lang=='ru'?'Отправить':'Send'}
						</Button>
					}
				</>
	)	
	// <Button view="primary" onClick={sendVote}>
	// 	{lang=='ru'?'Отправить':'Send'}
	// </Button>
}


