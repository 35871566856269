// import logo from './logo.svg';
// import './App.css';

import {
	// BrowserRouter as Router,
	HashRouter as Router,
	Switch,
	Route,
	useHistory,
	withRouter,
	Redirect,
	useRouteMatch,
	// Link,
	// useParams,
	// useHistory,
	// Redirect
} from "react-router-dom";


// import { IconDownload } from '@sberdevices/plasma-icons';
// import { Button, ActionButton } from '@sberdevices/plasma-ui';

import EntryPage from './pages/VoteEntryPage'
import VisitorPage from './pages/VotePage'
// import VisitorPage2 from './pages/VisitorPage2'
// import VisitorPage3 from './pages/VisitorPage3'
import SlidesPage from './pages/SlidesPage'
// import SlidesPage2 from './pages/SlidesPage2'
// import SlidesPage3 from './pages/SlidesPage3'
// import SlidesPage4 from './pages/SlidesPage4'
// import SlidesPage0 from './pages/SlidesPage0'
// import WallPage from './pages/wall/WallPage'
import CommentModerPage from './pages/ModerPage'
// import MainPage from './pages/wall/MainPage'

import PresListPage from './pages/PresListPage'
import LoginPage from './pages/LoginPage'

import ThesisPage from './pages/thesis/ThesisPage'
import ThesisSharePage from './pages/thesis/ThesisSharePage'

import api from "./api/api";



function AppClear({lang}) {
	const history = useHistory();
	let { path, url } = useRouteMatch();
	// let path_fix = path === '/' ? '' : path
	let path_fix = path === '/' ? '/' : path + '/'

	// api.init({
	// 	unauth_redirect_to: '/login',
	// 	history: history,
	// })

	api.addEventListener('unauth', (e) => {
		history.push(path_fix + 'pres/login')
	})

	return (
		<div className="App">

			{/* A <Switch> looks through its children <Route>s and
					renders the first one that matches the current URL. */}
			<Switch>

				<Route path={path_fix + "pres/login"}><LoginPage onLogin={()=>{ history.push(path_fix + 'pres')}} /></Route>

				<Route path={path_fix + "pres/:pres_id/slides/:slide_index/:slide_mode"}><SlidesPage /></Route>
				<Route path={path_fix + "pres/:pres_id/slides/:slide_index"}><SlidesPage /></Route>
				<Route path={path_fix + "pres/:pres_id/slides"}><SlidesPage /></Route>
				<Route path={path_fix + "pres/:pres_id"}><SlidesPage /></Route>

				<Route path={path_fix + "pres"}><PresListPage /></Route>
				<Route path={path_fix + "press"}><Redirect to={path_fix + "pres"} /></Route>

				{/* <Route path={path_fix + "wall"}><WallPage lang={lang} /></Route> */}
				<Route path={path_fix + "moder/:pres_id"}><CommentModerPage lang={lang} /></Route>
				{/* <Route path={path_fix + "moder"}><CommentModerPage lang={lang} /></Route> */}
				{/* <Route path={path_fix + "input"}><MainPage lang={lang} /></Route> */}
	
				<Route path={path_fix + "v/:votecode"}><VisitorPage lang={lang} /></Route>
				<Route path={path_fix + "vote/:votecode"}><VisitorPage lang={lang} /></Route>


				<Route path={path_fix + "t/:timestamp/d"}><ThesisSharePage lang={lang} light={false} /></Route>
				<Route path={path_fix + "t/:timestamp"}><ThesisSharePage lang={lang} light={true} /></Route>
				<Route path={path_fix + "thesis/dark"}><ThesisPage lang={lang} light={false} /></Route>
				<Route path={path_fix + "thesis"}><ThesisPage lang={lang} light={true} /></Route>

				<Route path={path_fix + "vote"}><EntryPage lang={lang} /></Route>
				<Route path={path_fix} exact><EntryPage lang={lang} /></Route>
				<Route path={path_fix}><Redirect to={path_fix} /></Route>

			</Switch>
		</div>
	);
}


function AppWithLang() {
	// let { path, url } = useRouteMatch();
	return (
			<Switch>
				<Route path="/ru"><AppClear lang="ru" /></Route>
				<Route path="/en"><AppClear lang="en" /></Route>
				{/* <Route path="/"><Redirect to={'/ru' + path} /></Route> */}
				{/* <Route exact path="/">exact<AppClear lang="ru" /></Route> */}
				<Route path="/"><AppClear lang="ru" /></Route>
			</Switch>
	)
}

// export default App;

const AppWithRouter = withRouter(AppWithLang)

export default function WrapApp() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  )
};

