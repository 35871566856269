import { IconArrowLeft, IconArrowRight, IconBell, IconCardstack, IconClose, IconEvent, IconMaximize, IconTimerStroke } from "@sberdevices/plasma-icons";
import { Button, Container, Radiobox, TabItem, Tabs, TextField } from "@sberdevices/plasma-ui";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// import { IconBell } from "@sberdevices/plasma-icons";

import {
	EyeOutlined,
	EyeInvisibleOutlined,
	QrcodeOutlined
} from '@ant-design/icons';

// import Chart from "react-apexcharts";
import HelpText from "../elems/HelpText";
import API from "../api/api";
import BaseLayout from "../elems/BaseLayout";
import { Col, Progress, Row } from "antd";

import * as Highcharts from 'highcharts';
// import HighchartsSankey from "highcharts/modules/sankey";


import HighchartsReact from 'highcharts-react-official';


import PromoSlide from "./slides/PromoSlide"; 
import IntroSlide from "./slides/IntroSlide"; 
import IntroVoteSlide from "./slides/IntroVoteSlide"; 
import VoteSlide from "./slides/VoteSlide"; 
import DevSlide from "./slides/DevSlide"; 
import WallSlide from "./slides/WallSlide"; 
import WordCloudSlide from "./slides/WordCloudSlide"; 



const tiktack = new Audio('/timer.mp3');
const gong = new Audio('/gong.mp3');



export function microTypographer(text, withHtmlWrapper = true) {

    let t = text.replace(/ (у|в|о|на|по|во|до|к|с|и|за|для|vs|из|под|его|её) /gi, ' $1&nbsp;');
    t = t.replace(/ ([^ ]{1,5})$/, '&nbsp;$1');
    
    if (!withHtmlWrapper) return t

    return <span dangerouslySetInnerHTML={{ __html:t }}/>
}


function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
function isFullScreen() {
    if (!document.fullscreenElement) {
        return false
    } else {
      if (document.exitFullscreen) {
        // document.exitFullscreen();
		return true
      }
	  return false
    }
  }



export default function SlidesPage() {
	const [slides, set_slides] = useState([]);
	// const [aggregation, set_aggregation] = useState([]);
	const [display_type, set_display_type] = useState('event');
	
	const [is_show_results, set_is_show_results] = useState(true);
	const [is_open_voting, set_is_open_voting] = useState(false);
	const history = useHistory()
	
	const {pres_id, slide_index, slide_mode} = useParams()

	let current_slide = null
	// let current_slide_index = 0
	let current_slide_index = parseInt( slide_index ) || 0

	const onKeyDown = (e) => {
		switch (e.key) {
			// case 'Escape': 
			case 'ArrowLeft': 
				// console.log('<')
				on_prev_slide()
				break;
			case 'Enter': 
			case 'ArrowRight': 
			case ' ':  
				// console.log('>')
				on_next_slide()
				break;
			default: 
				break;
		}
		// console.log(e.key, e.keyCode, lang)
	}

	const loadSlides = () => {
		API.get_pres_slides(pres_id).then( res => {
			set_slides([
				{ static_slide:'vox' },
				// { static_slide:'intro' },
				...(res || []).map(x=>{
					switch (x.slide_type) {
						case 'ranking':
						case 'multi_choice':
							return [
								{...x, static_slide:'intro_q'},
								{...x, static_slide:'q'},
								{ static_slide:'vox' },
							]
						case 'comment':
							return [
								{...x, static_slide:'wall'},
								{ static_slide:'vox' },
							]
						case 'word_cloud_robo':
							return [
								{...x, static_slide:'wordcloud'},
								{ static_slide:'vox' },
							]
						default:
							return [
								{...x, static_slide:'dev'},
							]
					}
				} ).flat(),
				// { static_slide:'vox' },
			])
			console.log('loadSlides', res)
		})
	}

	// useEffect(()=>{
	// 	loadSlides()
	// }, [pres_id])

	useEffect(()=>{
		let iid = setInterval(()=>loadSlides(), window.CONFIG.interval_get_slides || 5000)
		loadSlides()
		// set_is_open_voting(false)
		// API.set_focus(slide_id) // ++
		return () => clearInterval(iid);
	// }, [pres_id, slide_index ])
	}, [])

	// const loadAggregation = () => {
	// 	// console.log('loadAggregation', current_slide)
	// 	if (current_slide === null || !current_slide.slide_id) return
	// 	API.get_aggregation(current_slide.slide_id).then( res => {
	// 		set_aggregation(res)
	// 	})
	// 	loadSlides()
	// }

	// useEffect(()=>{
	// 	let iid = setInterval(()=>loadAggregation(), 5000)
	// 	loadAggregation()
	// 	// set_is_open_voting(false)
		
	// 	// API.set_focus(slide_id) // ++
	// 	return () => clearInterval(iid);
	// }, [pres_id, slide_index ])

	useEffect(() => {
        document.addEventListener('keydown', onKeyDown)
        // document.addEventListener('fullscreenchange', onFullscreenChange)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
            // document.removeEventListener('fullscreenchange', onFullscreenChange)
        }
    })
	
	if ( slides.length < 0 ) {
		return ''
	}

	// let lang = 'ru'
	// current_slide_index = ( slide_id && slides.findIndex(x=>x.slide_id == slide_id && x.static_slide == slide_mode) ) || 0;
	current_slide = slides[current_slide_index] || null;
	// let next_slide = slides[current_slide_index + 1] || null;
	// let prev_slide = slides[current_slide_index - 1] || null;

	


	const start_voting = () => {
		API.set_focus(current_slide.pres_id, current_slide.slide_id).then(res => {
			set_is_open_voting(true)
			tiktack.loop = true;
			tiktack.currentTime = 0;
			tiktack.play();
			gong.pause();
		})
	}
	const stop_voting = () => {
		set_is_open_voting(false)
		API.set_focus(current_slide.pres_id, -1);
		tiktack.pause();
		gong.loop = false
		gong.currentTime = 0;
		gong.play();
	}

	const on_next_slide = () => {
		// console.log('on_next_slide', current_slide_index, slides[current_slide_index + 1],slides)
		if (slides[current_slide_index + 1]) {
			// history.push('/pres/'+pres_id+'/slides/'+next_slide.slide_id + '/' + next_slide.static_slide)
			history.push('/pres/'+pres_id+'/slides/'+(current_slide_index+1))
			// API.set_focus(next_slide.slide_id) // ++
			// set_aggregation([])
			// loadAggregation(next_slide.slide_id)
		}
	}
	const on_prev_slide = () => {
		if (slides[current_slide_index - 1]) {
			// history.push('/pres/'+pres_id+'/slides/'+prev_slide.slide_id+ '/' + prev_slide.static_slide)
			history.push('/pres/'+pres_id+'/slides/'+(current_slide_index-1))
			// API.set_focus(prev_slide.slide_id) // ++
			// set_aggregation([])
			// loadAggregation(prev_slide.slide_id)
		}
	}

	if ( !current_slide ) {
		return ''
	}
	
	
	return (
		// <BaseLayout width={'1250px'} >
		// <BaseLayout width={'1250px'} onClick={()=>on_next_slide()}>
			// <BaseLayout width={'none'} onClick={()=>on_next_slide()}>
			<div onClick={()=>on_next_slide()}>
		{/* <Container style={{textAlign:'center-'}}> */}
			{/* <div style={{maxWidth:'700px', width: '100%', margin:'0px auto', display:'flex', flexDirection:'column'}}> */}
				{/* <HelpText /> */}
				{/* <br/> */}

				{/* {pres_id} / {slide_id} / {current_slide?.slide_id} // {next_slide?.slide_id} */}
				{/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( slides, true, "\t" ) }</pre></small> */}
				{/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( current_slide.choice_options, true, "\t" ) }</pre></small> */}
				{/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( aggregation, true, "\t" ) }</pre></small> */}

				{/* <p>{current_slide.pres_title[lang]} / {current_slide.slide_title[lang]}	</p> */}

				{/* {current_slide_index} | {next_slide?.static_slide || '--'} */}
				
				{/* {{current_slide.votecode}} */}

				{/* { current_slide.static_slide == 'vox' ? <PromoSlide votecode={current_slide.pres_votecode} />  : '' } */}
				{/* { current_slide.static_slide == 'intro' ? <IntroSlide votecode={current_slide.pres_votecode} />  : '' } */}

				{is_open_voting && <TimerPanel time={current_slide.slide_timer} /> }

				{/* { current_slide.static_slide == 'vox' ? <PromoSlide votecode={slides[0].pres_votecode} />  : '' } */}
				{ current_slide.static_slide == 'vox' ? <PromoSlide votecode={slides?.[1]?.pres_votecode || 5555} />  : '' }
				{/* { current_slide.static_slide == 'vox' ? <PromoSlide votecode={1111} />  : '' } */}
				{ current_slide.static_slide == 'intro' ? <IntroSlide votecode={5555} />  : '' }
				{ current_slide.static_slide == 'q' ? <VoteSlide is_open_voting={is_open_voting} current_slide={current_slide} is_show_results={is_show_results} />  : '' }
				{ current_slide.static_slide == 'intro_q' ? <IntroVoteSlide is_open_voting={is_open_voting} current_slide={current_slide} is_show_results={is_show_results} />  : '' }
				{ current_slide.static_slide == 'wall' ? <WallSlide is_open_voting={is_open_voting} current_slide={current_slide} is_show_results={is_show_results} />  : '' }
				{ current_slide.static_slide == 'wordcloud' ? <WordCloudSlide is_open_voting={is_open_voting} current_slide={current_slide} is_show_results={is_show_results} />  : '' }
				{ current_slide.static_slide == 'dev' ? <DevSlide is_open_voting={is_open_voting} current_slide={current_slide} is_show_results={is_show_results} />  : '' }

				<style>
					{`
						.en { opacity: 0.4 }
						.voxtoolbar { opacity: 0.0; transition: opacity 0.3s }
						.voxtoolbar:hover { opacity: 1; }
					`}
				</style>
				
				<div className="voxtoolbar" style={{display:'flex', alignItems:'center', justifyContent: 'center', position:'fixed', bottom:50, left:0, right:0, height:'150px'}}>
					<MyTabs index={display_type} items={[
									{id:'FullScreen', title: isFullScreen() ? <IconClose  color={'inherit'} /> : <IconMaximize  color={'inherit'} />,
										onClick:()=>{toggleFullScreen()}
									},
									// {id:'QR', title: <QrcodeOutlined  style={{fontSize: '24px'}} />},
									// {id:'Timer', title: <IconTimerStroke />},
									// {id:'Timer', title: <><IconTimerStroke /> Открыть голосование</>},
									...(
										(is_open_voting || (current_slide && current_slide.slide_id)) ? [
											{id:'ShowResult', title: is_show_results ? <EyeOutlined  style={{fontSize: '24px'}} /> : <EyeInvisibleOutlined  style={{fontSize: '24px'}} />, 
												onClick:()=>{set_is_show_results(!is_show_results)}
											},
											{
												id:'Open', 
												title: <><span style={{lineHeight:1}}>{!is_open_voting ? 'Открыть голосование' : 'Закрыть голосование' }</span></>, 
												onClick:()=>{ is_open_voting ? stop_voting() : start_voting() },
												// style:{backgroundColor:is_open_voting ? '#99a4b3' : 'transparent' }
												style:{backgroundColor:is_open_voting ? '#445566dd' : 'transparent' }
											},
										] : []
									),
									{id:'LeftArrow', title: <IconArrowLeft color={'inherit'} />,
										onClick:()=>{ on_prev_slide()  }
									},
									{id:'Slides', title: <span style={{lineHeight:1}}>{current_slide_index+1} / {slides.length}</span>},										
									{id:'RightArrow', title: <IconArrowRight  color={'inherit'} />, disabled: false,
										onClick:()=>{ on_next_slide() }
									},
								]} onChange={(id, x)=>{
									if (x.onClick) x.onClick();
								}} />
					
				</div>
				
		</div>
		// </BaseLayout>
	)
}




// const Toolbar = ({ ...rest }) => {

const timerStyle = {
	position: 'absolute',
    // top: '30vh',
    // left: '30%',
    right: '10vh',
    bottom: '10vh',
	// wist
    textAlign: 'center',
    zIndex: 1,
    borderRadius: '20px',
    padding: '30px',
    fontSize: '30px',
	background: "#445566dd", 
	color:'white',
	// lineHeight:1,
}


function TimerPanel({time}) {

	const [now, set_now] = useState(time);
	// const [now, set_now] = useState(3);



	useEffect(() => {
		let iid = setInterval(() => {
			if ( now > 0) {
				set_now( now - 1)

				if (now - 1 == 0) {
					// clearInterval(iid)
					gong.loop = false
					gong.currentTime = 0;
					gong.play();
					tiktack.pause();
					clearInterval(iid)	
					return
				}
			} else {
				// set_now( 0 )
				clearInterval(iid)	
			}
		}, 1000)
		return () => {
			clearInterval(iid)
		}
	}, [now])

	// useEffect(()=>{
	// 	let iid = setInterval(()=>loadAggregation(), 5000)
	// 	loadAggregation()
	// 	// API.set_focus(slide_id) // ++
	// 	return () => clearInterval(iid);
	// }, [pres_id, slide_index ])

	if (now == 0) {
		return (
			<div style={{...timerStyle, fontSize:'20px'}}>
				{/* <IconBell color={'white'} size="s" /> */}
				<small>Время вышло</small>
			</div>
		)
	}

	return (
		<div style={timerStyle}>
			{Math.floor(now/60)}:{(now % 60) < 10 ? '0' + now % 60 : now % 60}
			<br/>
			{/* {now/60} */}
		</div>
	)
}


// }

let MyTabs = ({items=[], index, onChange=(id)=>{}, ...rest }) => {
    // view={'clear'}
    return (
        <Tabs size={'l'} 
			// style={{background:'#bdc6d3a0'}}  
			style={{background:'#000000a0'}}  
			pilled={false} scaleOnPress={true} outlined={false} 
			onClick={(e)=>{ e.stopPropagation(); }} 
			{...rest}
		>
            {items.map(x=>(
                <TabItem 
                    key={x.id}
                    isActive={x.id === index} 
					disabled={x.disabled}
                    onClick={() => onChange(x.id, x)} 
					style={{lineHeight:0, ...x.style}}
					
                >    
                    {x.title} {x.disabled}
					{/* <br/> */}
					{/* <span style={{fontWeight:'normal', fontSize:'10px'}}>{x.id}</span> */}
                </TabItem>
            ))}
        </Tabs>
    );
    // IconPersone,
    //     IconAlarm,
    //     IconHouseSbol,
    //     IconCardstack,
    //     IconMessage,
    //     IconInfo,
    //     IconSettings,
}
