// import { Col, Row } from "antd";

import SlideLayout from "../../elems/SlideLayout";

export default function DevSlide({votecode, current_slide}) {
	return (
		<SlideLayout>
            {/* <br />
            <br />
            <br />
            <br />
            <br /> */}
            <div>
                <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( current_slide, true, "\t" ) }</pre></small>
            </div>
		</SlideLayout>
	)
}