import { Button, Card, CardContent, TabItem, Tabs, TextArea, TextField } from "@sberdevices/plasma-ui";
import { Col, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../api/api";
import BaseLayout from "../elems/BaseLayout";


function getComment() {
    return {
        body: 'Плюс аддитивных технологий заключается в конструкции детали, которую с помощью другой технологии просто не сделаешь ', // + 'Плюс аддитивных технологий заключается в конструкции детали, которую с помощью другой технологии просто не сделаешь',
        author: 'Виталий Смелов',
        body_en: 'The advantage of additive technologies lies in the design of parts that simply cannot be made with the help of other technologies.',
        author_en: 'Vitaly Smelov',
    }
}

// Шикарность тащемто
// Chicness of the drag
// к0т

// Да лучший комент
// Da best cooment


function CommentCard({comment={}, ...rest}) {
    // let content = getComment();/

    // const [moder_state, set_moder_state] = useState('new')
    const [content, set_content] = useState(getComment())
    const [wait_update, set_wait_update] = useState(true)

    useEffect(()=>{
        if (wait_update) {
            // console.log('-')
            set_content({ ...comment })
            set_wait_update(false)
        }
    },[comment]) 

    const sendUpdate = (content) => {
        console.log(content)
        API.set_wall_moderate(content).then(res => console.log(res), console.log)
    }
    // const sendStatus = () => {
    //     console.log(content)
    //     API.set_wall_moderate(content).then(res => console.log(res), console.log)
    // }

    return (
        
        <Card style={{marginBottom:10, background:'#ffffff90'}} {...rest}>
            <CardContent>
                {/* {JSON.stringify(comment)} */}

                <Row gutter={[10,10]}>
                    <Col span={12}>
                        <Input.TextArea autoSize={true} 
                            value={content.comment} 
                            onChange={e => set_content({ ...content, comment: e.target.value})} 
                            style={{color:'black', background:'white', border:'none', fontFamily: '"SB Sans Text",sans-serif'}}></Input.TextArea>
                        <Input 
                            value={content.author} 
                            onChange={e => set_content({ ...content, author: e.target.value})} 
                            style={{margin:'10px 0', color:'black', background:'white', border:'none'}} />
                    </Col>
                    <Col span={12}>
                        <Input.TextArea autoSize={true} 
                            value={content.comment_eng} 
                            onChange={e => set_content({ ...content, comment_eng: e.target.value})} 
                            style={{color:'black', background:'white', border:'none', fontFamily: '"SB Sans Text",sans-serif'}}></Input.TextArea>
                        <Input 
                            value={content.author_eng} 
                            onChange={e => set_content({ ...content, author_eng: e.target.value})} 
                            style={{margin:'10px 0', color:'black', background:'white', border:'none'}} />
                    </Col>
                </Row>


                <div style={{display:'flex', justifyContent: "center", alignItems:'center', fontSize: 11}}>

                    #{content.answer_id}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <MyTabs index={content.moderated} items={[
                        {id:2, title: 'Подсветить', style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                        {id:1, title: 'Одобрено', style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                        {id:0, title: 'На проверке', style:{background:'yellow'} },
                        {id:-1, title: 'Скрыть', style:{background:'red', color:'white'} },
                    ]} onChange={e => {
                        // console.log({ ...content, moderated: e})
                        sendUpdate({ ...content, moderated: e})
                        // sendUpdate({ 
                        //     answer_id: content.answer_id, 
                        //     slide_id: content.slide_id, 
                        //     presentation_id: content.presentation_id, 
                        //     moderated: e})
                        set_content({ ...content, moderated: e})
                        set_wait_update(true)
                    }} />

                    {JSON.stringify({...content, moderated:0 }) == JSON.stringify({...comment, moderated:0 }) ? '' : (
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;                            
                            <Button size="s" view="primary" disabled={wait_update} onClick={()=>{
                                sendUpdate(content);
                                set_wait_update(true)
                            }}>Обновить текст</Button>
                        </div>
                    )}

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {content.language === 0 ? 'RU' : 'EN'}

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {content.ts_created}

                    
                </div>
                
            </CardContent>

        </Card>
    )
}

export default function CommentModerPage() {

    const [list_comments, set_list_comments] = useState([])
    const [pres, set_pres] = useState([])
    const [filter_slide_id, set_filter_slide_id] = useState(0)
    const [filter_moderated_key, set_filter_moderated_key] = useState(3)

    const {pres_id} = useParams()

    const get_pres = (withReset = false) => {
        API.get_pres_slides(pres_id).then(res => {
            set_pres(res)
            if (withReset) {
                set_filter_slide_id(res[0]?.slide_id || 0)
            }
        }, console.log)
    }

    const get_moder = () => {
        if ( pres_id != 0 && filter_slide_id != 0) {
            API.get_wall_moderate(pres_id, filter_slide_id).then(res => set_list_comments(res), console.log)
        }
    }

    useEffect(()=>{
        // let iid = setInterval(() => get_moder(), 3000)
        // get_moder()
        get_pres(true)
        // return () => clearInterval(iid)
    },[]) 

    useEffect(()=>{
        let iid = setInterval(() => get_moder(), 3000)
        get_moder()
        get_pres()
        return () => clearInterval(iid)
    },[filter_slide_id]) 

    const count_by_mod_key = (mod_key) => {
        let n = list_comments
            .filter(x=> x.slide_id === filter_slide_id)
            .filter(x=> x.moderated === mod_key || mod_key === 3)
            .length
        return ' (' + n + ')'
    }

    return (
        <BaseLayout logo={false} width="100%">

            {/* {JSON.stringify(pres.filter(x => x.slide_type == 'comment'))} */}

            {/* {pres.filter(x => x.slide_type == 'comment').map((x,i)=> (
                <div>
                
                {x.choice_title?.ru}
                </div>
            ))} */}

            <div style={{display:'flex', justifyContent:'center'}}>
                    <MyTabs index={filter_slide_id} items={[
                        // {id:2, title: 'Подсветить', style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                        // {id:1, title: 'Одобрено', style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                        // {id:0, title: 'На проверке', style:{background:'yellow'} },
                        // {id:-1, title: 'Скрыть', style:{background:'red', color:'white'} },
                        ...pres
                            // .filter(x => x.slide_type == 'comment')
                            .map((x,i)=> (
                            {
                                id: x.slide_id,
                                title: x.choice_title?.ru,
                                // {/* {x.slide_title?.ru} */}
                                // {x.choice_title?.ru}
                            }
                            ))
                        ]} onChange={e => set_filter_slide_id(e)} />
            </div>
            <br />
            { pres && pres.find(x=>x.slide_id === filter_slide_id)?.slide_type === 'comment' && (<>
            
                {/* , justifyContent: 'space-between' */}
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <h2>Отзывы</h2>
                        {/* &nbsp;
                        &nbsp;
                        &nbsp; */}
                        {/* <h1 style={{margin:20}}>Отзывы</h1> */}
                        <MyTabs index={filter_moderated_key} items={[
                                {id:3, title: 'Все' + count_by_mod_key(3) },
                                {id:2, title: 'Подсвеченные' + count_by_mod_key(2), style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                                {id:1, title: 'Одобренные' + count_by_mod_key(1), style:{background:'var(--plasma-colors-button-accent)', color:'white'} },
                                {id:0, title: 'На проверке' + count_by_mod_key(0), style:{background:'yellow'} },
                                {id:-1, title: 'Скрытые' + count_by_mod_key(-1), style:{background:'red', color:'white'} },
                            ]} onChange={e => set_filter_moderated_key(e)} />
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div style={{width:94}}></div>
                </div>

                {/* {JSON.stringify(list_comments)} */}
                {/* {Array.from({ length: 5 }, (v, k) => k).map((x,i)=>( */}
                {list_comments
                    .filter(x=> x.slide_id === filter_slide_id)
                    .filter(x=> x.moderated === filter_moderated_key || filter_moderated_key === 3)
                    .map((x,i)=>(
                    <CommentCard key={x.answer_id} comment={x}/>
                ))}
            </>)}
        </BaseLayout>
    )
}


let MyTabs = ({items=[], index, onChange=(id)=>{}, ...rest }) => {
    // view={'clear'}
    return (
        <Tabs size={'s'} 
			style={{background:'#bdc6d350'}}  
			// style={{background:'#000000a0'}}  
			pilled={false} scaleOnPress={true} outlined={false} 
			onClick={(e)=>{ e.stopPropagation(); }} 
            // view={'secondary'}
			{...rest}
		>
            {items.map(x=>(
                <TabItem 
                    key={x.id}
                    isActive={x.id === index} 
					disabled={x.disabled}
                    onClick={() => onChange(x.id, x)} 
					// style={{lineHeight:0, ...x.style}}
                    style={x.id === index ? x.style : {}}
                    
					
                >    
                    {x.title} 
                    {/* {x.disabled} */}
					{/* <br/> */}
					{/* <span style={{fontWeight:'normal', fontSize:'10px'}}>{x.id}</span> */}
                </TabItem>
            ))}
        </Tabs>
    );
    // IconPersone,
    //     IconAlarm,
    //     IconHouseSbol,
    //     IconCardstack,
    //     IconMessage,
    //     IconInfo,
    //     IconSettings,
}

