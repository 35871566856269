import { IconCardstack } from "@sberdevices/plasma-icons";
import { Button, TextField } from "@sberdevices/plasma-ui";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import API from "../api/api";
import BaseLayout from "../elems/BaseLayout";

export default function VisitorPage({lang = 'ru'}) {
	
	const [votecode, set_votecode] = useState(false);
	const history = useHistory()

	const send = () => {
		API.get_vote_slide(votecode).then( res => {
			// set_slides(res)
			history.push('/' + lang + '/vote/' + votecode)
		})
	}
	return (
		<BaseLayout width={500} logo={false} topcenter=" "topleft={' '} topright={' '}>
			
			{/* <Button view={'clear'} style={{position:'absolute', right:20, top:35}} onClick={() => {history.push(lang == 'ru' ? '/en' : '/ru')}}>{ lang == 'ru' ? 'EN' : 'RU' }</Button> */}


			{/* <p>SberVox</p> */}
			<img style={{width:'100px'}} src="/sbervox_outline.svg" />

			<h2 style={{lineHeight:1.2}}>
				{lang == 'ru' ?
					<>Для участия в опросе <br/> укажите номер на слайдах <br/>или отсканируйте qr-code</> :
					<>To participate in the survey, <br/>input the number on the slides <br/>or scan the qr-code</> 
				}
			</h2>

			<TextField 
				label={ lang == "ru" ? "ID слайдов" : "Slide ID"}
				contentLeft={<IconCardstack/>} 
				onChange={(e)=>set_votecode(e.target.value)}
				// onKeyPress={(e)=>{ if (e.key === 'Enter') { set_votecode(e.target.value) } }}
				onKeyPress={(e)=>{ if (e.key == 'Enter') { send() } }}
				// onKeyDown={(e)=>{ if (e.key === 'Enter') { set_votecode(e.target.value) } }}
				/>

			<br/>

			<Button view="primary" onClick={()=>send()}>{lang=="ru" ? "Войти" : "Segn in"}</Button>


		</BaseLayout>
	)
}