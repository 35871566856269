import { Container } from "@sberdevices/plasma-ui";
import { Col, Row } from "antd";
import Footer from "./Footer";


function topLeft() {
    return (
        // <svg viewBox="0 0 2381 502" width="200" style={{verticalAlign: 'middle'}}>
        //     <use href="sber_logo.svg#Page-1"></use>
        // </svg>
        <>
        {/* <img style={{width:'180px', marginBottom:'-30px'}} src="/club.svg" /> */}
        {/* <img style={{width:'260px', marginBottom:'-30px'}} src="/club_inline.svg" /> */}
        </>
    )
}

function topCenter() {
    return (
        // <img width="160"  src="/sbervox2_logo.png"  style={{verticalAlign: 'middle', maxWidth:'100%', margin:-5}} />
        <img style={{width:'160px'}} src="/sbervox_outline.svg" />
    )
}

function topRight() {
    return ''
    return (
        // <img src="/sber_180.png" width="200" style={{verticalAlign: 'middle', maxWidth:'100%', margin:-5}} />
        <img src="/sber_180_outline.svg" width="200" style={{verticalAlign: 'middle', maxWidth:'100%', margin:-5}} />
    )
}



export default function BaseLayout({children, width = '100%', logo = false, topleft, topcenter, topright, ...rest}) {
    topleft = topleft || topLeft()
    topcenter = topcenter || topCenter()
    topright = topright || topRight()
    return (
        // <Container className="main_center_wrapper" style={{maxWidth:width, width: '100%', margin:'0px auto', paddingTop:'20px'}} {...rest}>
        <div className="main_center_wrapper" {...rest}>
            {/* <div> */}

                {/* SBER VOX */}

                {
                    logo ? <>

                        <img src="/sber_180.png" width="200" style={{position:'absolute', top: '40px', right:'40px'}} />
                        {/* <img src="/airi_logo.png" width="220" style={{position:'absolute', top: '45px', right:'40px'}} /> */}
                        <img src="/sber_logo.svg" width="200" style={{position:'absolute', top: '40px', left:'50px'}} />
                        {/* <img src="/sber_logo_black.svg" width="200" style={{position:'absolute', top: '40px', left:'50px'}} /> */}
                        <svg viewBox="0 0 2381 502" width="200" style={{position:'absolute', top: '40px', left:'50px'}}>
                            <use href="sber_logo.svg#Page-1"></use>
                        </svg>
                    </> : ''
                }

                {/* <div> */}

                

                    <div style={{display:'flex', flexDirection:'column', width: '100%'}}>

                        <Row gutter={0} wrap={true} align="middle" style={{width:'100%', marginBottom: 20}}>
                            <Col span={12} sm={8}>
                                {topleft}
                            </Col>
                            <Col span={0} sm={8} style={{textAlign:'center'}}>
                                {topcenter}
                            </Col>
                            <Col span={12} sm={8} style={{textAlign:'right'}}>
                                {topright}
                            </Col>

                            <Col span={24} sm={0} style={{textAlign:'center'}}>
                                {topcenter}
                            </Col>
                        </Row>

                        <div style={{maxWidth:width, width:'100%', margin:'0 auto'}}>
                            {children}
                        </div> 
                    </div>

                {/* </div> */}
                {/* <div></div> */}

                <Footer />
            {/* </div>             */}

            <style>
                    {`
                        .main_center_wrapper{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100vh;
                            align-items: center;
                            box-sizing: border-box;
                            width:100vw;
                            padding: 30px 30px 0;
                        }
                    `}
            </style>
                        
        </div>
        // </Container>
    )
}