import { Button, Spinner, TextArea, TextField } from "@sberdevices/plasma-ui"
import { message } from "antd"
import { useState } from "react"
import { useHistory } from "react-router"
import API from "../../api/api"
import { DontCloseNotes } from "./EmptyVotekit"


export default function CommentVotekit({lang="ru", slide={}}) {
	const lang_str = (strobj_with_langkey = []) => strobj_with_langkey[lang] || strobj_with_langkey['ru'] || ''


    let [text, setText] = useState('')
    let [name, setName] = useState('')

    const history = useHistory()


    let [is_loading, set_loading] = useState(false)
    let [is_sent, set_is_sent] = useState(false)


    // const success = () => {
    //     message.success({
    //         content: lang == 'ru' ? 'Спасибо! Ваше сообщение отправлено' : 'Thanks! Your message has been sent',
    //         className: 'custom-class',
    //         style: {
    //             marginTop: '40vh',
    //             borderRadius: '100px',
    //             padding: '40px'
    //         },
    //     });
    // };

    const send = () => {
        if (text.trim() == '' || is_sent) return

        set_loading(true)

        API.send_wall_comment(
			slide.pres_votecode,
            slide.pres_id,
            slide.slide_id,
            text,
            name,
            lang
        )
            .then(res => {
                console.log('res', res)
                // setTimeout(()=> {
                    set_loading(false);
                    // success()
                    // setText('')
                    // setName('')

					set_is_sent(true)
                // },300)
            }, err => {
                console.log('err', err)
                set_loading(false);
            });
    }

	return (
		<div>
			<h2 style={{lineHeight:1, marginBottom:0}}>{ lang_str(slide.choice_title) }</h2>
			<p><small>{ lang_str(slide.choice_desc) }</small></p>
			{/* <br /> */}


			{/* <div style={{textAlign:'center', marginTop:'2.5rem'}}> */}
                    {/* <br /> */}
                    {/* <img className="sber_180" src={lang=="ru" ? "/sber_180.png" : "/sber_180_en.png"} style={{ width:'200px'}} /> */}

                    {/* <h1 style={{fontSize: '3rem', lineHeight:1, margin:'1rem 0 1rem'}}>{lang =='ru' ? 'Обратная связь' : 'Feedback'}</h1> */}
                    {/* <p>{ lang=='ru' ? <>Оставьте ваш комментарий <br/>или&nbsp;зайдайте&nbsp;вопрос</> 
                        : <>Leave your comment <br/>or ask a question</>}</p> */}
                {/* </div> */}

                <TextArea 
                    className="TextFieldArea" 
                    label={ lang=='ru' ? "Текст отзыва или вопроса" : 'Review or question text'}  
                    value={text} 
                    onChange={e => setText(e.target.value)} 
                    style={{ margin: '10px auto', width:'100%'}}
					disabled={is_sent}
                />
   
                <TextField 
                    // as="textarea"
                    className="TextField"
                    label={lang == "ru" ? "Представьтесь (опционально)" : 'Your name (optional)'}
                    // rows={10}
                    value={name} 
					disabled={is_sent}
                    // defaultValue={val} 
                    onChange={e => setName(e.target.value)} 
                    // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
                    // style={{ margin: '10px auto', width:'100%'}}
                />

                <br />

				{is_sent ? 
					<div style={{textAlign:'center'}}>
						{lang == 'ru'? 'Спасибо за ваше сообщение!' : 'Thanks for your message!'}
						<br/>
						<br/>
						<DontCloseNotes lang={lang} />
					</div>
					:
					<Button view="primary" disabled={text.trim() == ''} onClick={() => { send() }} >
					{/* <Button view="primary" onClick={() => { send() }} > */}
						{ is_loading ? <Spinner color="white" size={20} /> : ( lang=="ru" ? 'Отправить' : 'Send') }
					</Button>
				}

				<style>
              {`

.TextFieldArea textarea { 
	white-space: normal !important;
  }

  
  `}
          </style>
  {/* .TextField input {
	  color:#2e3340;
	  background: #ffffff9b;
  }
  .TextFieldArea textarea { 
	height: 30vh; 
	resize: none;
	white-space: normal;
	background: #ffffff9b;
	overflow: auto;
	color:#2e3340;
	width:100%;
  }
  .TextField input:focus,
  .TextFieldArea textarea:focus { 
	background: #fff;
  }
  .TextField span,
  .TextFieldArea span {
	  color: #2e334077;
  } */}


		</div>
	)

}