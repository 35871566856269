import { Button, Checkbox, Container, Radiobox } from "@sberdevices/plasma-ui";
// import Base from "antd/lib/typography/Base";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../api/api";
// import Footer from "../elems/Footer";

import BaseLayout from "../elems/BaseLayout";


// import SortPage from './votekit/SortPage'
// import { Col, Row } from "antd";


import EmptyVotekit from "./votekit/EmptyVotekit";
// import { DontCloseNotes } from "./votekit/EmptyVotekit";
import RankerVotekit from "./votekit/RankerVotekit";
import MultiChoiceVotekit from "./votekit/MultiChoiceVotekit";
import CommentVotekit from "./votekit/CommentVotekit";


export default function VisitorPage({lang = 'ru'}) {
	const [slide, set_slide] = useState({});
	const {votecode} = useParams()
	const history = useHistory()

	const lang_str = (strobj_with_langkey = []) => strobj_with_langkey[lang] || strobj_with_langkey['ru'] || ''

	const loadVoteSlide = () => {
		api.get_vote_slide(votecode).then( res => {
			console.log(res?.slide_id, slide?.slide_id)

			set_slide(res) 
		}, err => {
			history.push('/' + lang + '/vote' )
		})
	}

	useEffect(()=> {
		loadVoteSlide();
		let iid = setInterval(loadVoteSlide, window.CONFIG.interval_get_vote_slide || 5000)
		return () => clearInterval(iid);
	}, [votecode])
	// }, [votecode, last_json])

	if (!slide || !slide.pres_title) return (
		<Container>
			{/* -- */}
			{/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( slide, true, "\t" ) }</pre></small> */}
		</Container>
	)

	// <img width="160"  src="/sbervox2_logo.png"  style={{verticalAlign: 'middle', maxWidth:'100%', margin:-5}} />
	return (
		<BaseLayout width={500} logo={false} topcenter=" "topleft={' '} topright={ ' '}>
		{/* <Container> */}
			{/* <div style={{maxWidth:'500px', width:'100%', margin:'20px auto', display:'flex', flexDirection:'column'}}> */}

			{/* <Button view={'clear'} style={{position:'absolute', right:20, top:35}}
				onClick={() => {history.push(lang == 'ru' ? '/en/vote/' + votecode : '/ru/vote/' + votecode)}}
			>
				{ lang == 'ru' ? 'EN' : 'RU' }
			</Button> */}

			
			<p style={{marginRight: '80px', marginTop:0}}>
				{ lang_str(slide.pres_title) } { slide.slide_id ? '/ ' + lang_str(slide.slide_title) : ''}
			</p>
			

			<div style={{display:'flex', flexDirection:'column', width: '100%'}}>
			{/* { slide.choice_id ? <RankerVotekit lang={lang} slide={slide}/> : <EmptyActivePage lang={lang} />} */}
			{ slide.slide_type === '' && <EmptyVotekit lang={lang} />}
			{ slide.slide_type === 'comment' && <CommentVotekit lang={lang} slide={slide} />}
			{ slide.slide_type === 'ranking' && <RankerVotekit lang={lang} slide={slide}/> }
			{ slide.slide_type === 'multi_choice' && <MultiChoiceVotekit lang={lang} slide={slide}/> }

			</div>
			{/* <Footer /> */}

			{/* {JSON.stringify(sended_ids) } */}
			{/* <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( slide, true, "\t" ) }</pre></small> */}

			{/* </div> */}
		{/* </Container> */}
		</BaseLayout>
	)
}




