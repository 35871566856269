

export  function prepare_pres_slides(data_result) {
	console.log('prepare_pres_slides', data_result)
	return data_result.slide_list
				.sort((a,b)=>a.number - b.number)
				.map( x => prepare_slide(data_result.presentation, x) )
}
export  function prepare_vote_slide(data_result) {
	return prepare_slide(data_result.presentation, data_result?.slide)
}

function prepare_slide(pres_data, slide_data = {}) {
	return {
		pres_id:  pres_data.presentation_id,
		pres_title: {
			ru: pres_data.title || '',
			en: pres_data.title_eng || ''
		},
		pres_votecode: pres_data.votecode || 5555,

		slide_id:   slide_data?.slide_id,
		slide_title: {
			ru: 	slide_data?.title || '',
			en: 	slide_data?.title_eng || '',
		},
		slide_type: slide_data?.slide_type || '',

		slide_timer: slide_data?.timer || 15,

		...prepare_choice( slide_data?.details ),

		'#src': slide_data
	}
}

function prepare_choice(details) {
	if (details && details.length && details[0]) {
		let d = details[0];
		return {
			// choice_id:  d.multi_choice_id,
			// choice_id:  d.ranking_id,
			choice_id:  d.details_id || d.detail_id,
			choice_max: d.max_choice,
			choice_title: {
				ru: 	d?.title || '',
				// en: 	d?.title_eng || '',
				en: '',
			},
			choice_desc: {
				ru: 	d?.description || '',
				en: 	d?.description_eng || '',
			},
			choice_options: (d.options || [])
				.sort((a,b) => a.number - b.number)
				.map(x => (
				{
					// opt_id: x.multi_choice_option_id,
					// opt_id: parseInt(x.ranking_option_id),
					opt_id: parseInt(x.option_id),
					opt_title: {
						ru: x?.title || '',
						// en: x?.title_eng || '',
						en: '',
					},
				}
			)),
		}
	} else {
		return {
			choice_title: {
				ru : '',
				en : ''
			},
			choice_desc : {
				ru: '',
				en: ''
			},
			choice_options: []
		}
	}
}

