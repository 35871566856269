
export default function Footer() {
	return (
		<div className="footer" style={{textAlign:'center', margin: '0.5rem 0'}}>
			<small style={{fontSize:'15px'}}>powered by</small>
			{/* <img src="sberai_logo_black.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/>  */}
			{/* <img src="sberai_logo_black.svg" style={{verticalAlign: 'middle', width: '110px', margin: '10px'}}/>  */}
			{/* <img src="sberai_logo_black2.svg" style={{verticalAlign: 'middle', width: '110px', margin: '10px'}}/>  */}

			<svg viewBox="0 0 2381 502" style={{verticalAlign: 'middle', width: '110px', margin: '10px'}}>
				<use href="sberai_logo_black2.svg#SBER_AI_LOGO_Eng"></use>
			</svg>
		</div>
	)
}