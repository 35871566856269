// import { Col, Row } from "antd";



import { Card, CardBody, CardContent, Button } from "@sberdevices/plasma-ui";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import API from "../../api/api";
import BaseLayout from "../../elems/BaseLayout";
import SlideLayout from "../../elems/SlideLayout";
import VotePresQR from "../../elems/VotePresQR";


// function getComment() {
//     return {
//         body: 'Плюс аддитивных технологий заключается в конструкции детали, которую с помощью другой технологии просто не сделаешь',
//         author: 'Виталий Смелов',
//     }
// }


function CommentCard({body='', body_en='', author='', marked=false, ...rest}) {
    // let content = getComment();
    return (
        
        // <Card style={{marginBottom:10, background: marked ? '#00f60b' : '#ffffff90'}} {...rest}>
        // <Card style={{marginBottom:10, background: marked ? 'linear-gradient(45deg, #32ffcb, #33e63b)' : '#ffffff90'}} {...rest}>
        // <Card style={{marginBottom:10, background: marked ? 'linear-gradient(45deg, #32ffcb, #03d900)' : '#ffffff90'}} {...rest}>
        // <Card style={{marginBottom:10, background: marked ? 'linear-gradient(45deg, #32ffcb, #03d900, #b9e400)' : '#ffffff90'}} {...rest}>
        // <Card style={{marginBottom:10, background: marked ? '#33e63b' : '#ffffff90'}} {...rest}>
        <Card style={{marginBottom:10, background: marked ? 'linear-gradient(45deg, #32ffcb70, #33e63b50)' : '#ffffff20'}} {...rest}>
            <CardContent>
                {/* <h1>11«"'``,,</h1> */}
                {/* CommentCard */}

                <p style={{margin:0}}>{body}</p>
                <p className="en" style={{marginTop:0, fontSize:14, lineHeight:1.2}}>{body_en}</p>
                <b>{author}</b>
                
            </CardContent>
        </Card>
    )
}

export default function WallSlide({lang="ru", votecode=5555, current_slide={}}) {

    let cols =  window.CONFIG.wall_cols || 4
    const [list, set_list] = useState([])

    const get_moder = () => {
        // API.get_wall_moderate().then(console.log, console.log)
        API.get_wall_aggregation(current_slide.slide_id, window.CONFIG.def_show_comments || 1).then(res => {
            set_list(res)
        }, console.log)
    }

    useEffect(()=>{
        let iid = setInterval(() => get_moder(), window.CONFIG.interval_get_wall_aggregation || 3000)
        get_moder()
        return () => clearInterval(iid)
    },[]) 

    // <BaseLayout width={'100%'}>
    return (
        <SlideLayout>
            {/* <br/>
            <br/>
            <br/> */}
            
            <Row align="bottom">
                <Col flex="auto">
                
                    {/* <h1 style={{lineHeight:1.2, marginBottom: 0}}> */}
                    <h1 style={{lineHeight:1.2, marginBottom: 0, fontSize:'40px'}}>
                        {current_slide.choice_title['ru']}
                    </h1>
                    {/* <br/> */}
                    <h2 className="en" style={{fontSize:'20px', lineHeight:1.2, marginTop:'10px', marginRight: '60px'}}>{current_slide && current_slide.choice_title && current_slide.choice_title['en']}</h2>

                </Col>
                <Col span={3} style={{marginTop:-55, paddingBottom: 20}}>
                    <VotePresQR votecode={current_slide.pres_votecode} />
                </Col>
            </Row>
                {/* <div style={{position:'absolute', bottom:'60px', right: '60px   ', width: '30vh', height:'30vh'}}>
                    <VotePresQR votecode={current_slide.pres_votecode} />
                </div> */}
            
            
            <Row gutter={[10,10]} wrap={false} style={{alignItems: 'flex-start'}}>
                {Array.from({ length: cols }, (v, k) => k).map((c,ii)=>(
                    <Col key={ii} span={24/cols} style={{display: 'flex', flexDirection: 'column-reverse'}}>
                        { list.map((x,i)=> (i % cols == ii) &&  (
                            <CommentCard key={i} 
                                marked={x.moderated == 2}
                                body={lang == "ru" ? x.comment : x.comment_eng} 
                                body_en={x.comment_eng} 
                                author={lang == "ru" ? x.author : x.author_eng} />
                        )) }
                    </Col>
                ))}
            </Row>

            {/* <div>
                <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( current_slide, true, "\t" ) }</pre></small>
            </div> */}
            {/* <Button onClick={() => get_moder()}>get moder wall</Button> */}
            </SlideLayout>
    )
    // </BaseLayout>
}


// export default function WallSlide({votecode, current_slide}) {
// 	return (
// 		<>
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <div>
//                 <small style={{lineHeight:1, fontSize:'10px'}}><pre>{ JSON.stringify( current_slide, true, "\t" ) }</pre></small>
//             </div>
// 		</>
// 	)
// }