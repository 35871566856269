import { Col, Progress, Row } from "antd";
import { useEffect, useState } from "react";
import API from "../../api/api";
import SlideLayout from "../../elems/SlideLayout";
import VotePresQR from "../../elems/VotePresQR";


export default function WordCloudSlide({current_slide, is_show_results, is_open_voting}) {
	const [aggregation, set_aggregation] = useState([]);

	const loadAggregation = () => {
		// console.log('loadAggregation', current_slide)
		if (current_slide === null || !current_slide.slide_id) return
		API.get_aggregation(current_slide.slide_id).then( res => {
			set_aggregation(res)
		})
		// loadSlides()
	}

	useEffect(()=>{
		// let iid = setInterval(()=>loadAggregation(), 5000)
		loadAggregation()
		// set_is_open_voting(false)
		// API.set_focus(slide_id) // ++
		// return () => clearInterval(iid);
	// }, [pres_id, slide_index ])
	}, [current_slide])

	return (
		<SlideLayout>
			{/* {is_open_voting && <TimerPanel time={current_slide.slide_timer} /> } */}

			{/* <img style={{maxWidth:'160px', margin:'20px auto -45px'}} src="/sbervox2_logo.png" /> */}
			{/* <img style={{maxWidth:'180px', margin:'30px auto -25px'}} src="/sbervox2_logo.png" /> */}
			{/* <img style={{maxWidth:'180px', margin:'30px auto -25px'}} src="/sbervox_white.png" /> */}
			{/* <img style={{maxWidth:'200px', margin:'10px auto -70px'}} src="/sbervox_logo.png" /> */}

				<Row wrap={false} gutter={30}>
					<Col flex="auto">
						{/* <br/> */}
						{/* <br/> */}
						{/* <br/> */}
						{/* <p>
							{current_slide.pres_title['ru']} / {current_slide.slide_title['ru']}
							<br/><span className="en">{current_slide.pres_title['en']} / {current_slide.slide_title['en']}</span>
						</p> */}

						
						<h1 style={{lineHeight:1.2, marginBottom: 0, fontSize:'40px'}}>
						{/* <h1 style={{lineHeight:1.2, marginBottom: 0}}> */}
							{current_slide.choice_title['ru']}
						</h1>
						{/* <br/> */}
						<h2 className="en" style={{fontSize:'20px', lineHeight:1.2, marginTop:'10px', marginRight: '60px'}}>{current_slide && current_slide.choice_title && current_slide.choice_title['en']}</h2>

						{/* {current_slide.choice_desc['ru'] && (<p style={{lineHeight:1.2}}><small>
							{current_slide.choice_desc['ru']}
							<span className="en">{current_slide && current_slide.choice_title && (<><br/>{current_slide.choice_desc['en']}</>)}</span>
						</small></p>) } */}

						{/* <br/> */}
					
				<pre style={{lineHeight:1, fontSize:'10px'}}>{JSON.stringify(aggregation, true, "\t")}</pre>
				{/* <pre style={{lineHeight:1, fontSize:'10px'}}>{JSON.stringify(current_slide.choice_options, true, "\t")}</pre> */}
					</Col>
					<Col style={{textAlign:'right'}}>
						<br/>
						{/* <br/> */}
						{/* <br/> */}
						{/* <br/> */}
						{/* <img width="190" src="/qr-code3.png" /> */}
						<VotePresQR width="190px" votecode={current_slide.pres_votecode} />
						<br/>
						<br/>

					</Col>
				</Row>

						{(current_slide?.choice_options || [])
							.sort((a,b)=>(aggregation.find(x=>parseInt( x.rank_choice_option_id) == b.opt_id)?.count||0) - (aggregation.find(x=>parseInt( x.rank_choice_option_id) == a.opt_id)?.count || 0))
							// .sort((a,b)=>(a.opt_id - b.opt_id))
							
							.map((x,i)=> (
								<Row key={i} gutter={30}>
									{/* <Col span={1}>
									</Col> */}
											<Col span={12}>
		
													<h2 key={x.opt_id} style={{lineHeight:1.2, margin: '8px 0', fontSize:'28px'}}>
														{x.opt_title['ru']} 
														{/* — {aggregation.find(a=>a.multi_choice_option_id == x.opt_id)?.count || 0} */}
														{x.opt_title['en'] ? <><div className="en" style={{fontSize:'18px'}}>{x.opt_title['en']}</div></> : '' }
														{/* <br/>{x.opt_id} */}
		
														{/* {(aggregation.find(a=> parseInt( a.rank_choice_option_id ) === x.opt_id)?.count || 0 ) / (aggregation.reduce((acum, val) => acum + val.count, 0) || 1 ) } */}
													</h2>
		
											</Col>
									<Col span={12}>
												{/* <Progress  style={{ margin: '20px 0'}} strokeWidth={20} percent={is_show_results ? (aggregation.find(a=> parseInt( a.rank_choice_option_id ) === x.opt_id)?.count || 0 ) / (aggregation.reduce((acum, val) => acum + val.count, 0) || 1) * 100 : 0} size="small" strokeColor={'green'} showInfo={false} /> */}
												{/* background: 'url(/bar.jpg)', backgroundSize:'100% 100%' */}
												<Progress  
												style={{ margin: '10px 0', 
											}} 
												strokeWidth={40} 
												percent={is_show_results ? (aggregation.find(a=> parseInt( a.rank_choice_option_id ) === x.opt_id)?.count || 0 ) / (aggregation.reduce((acum, val) => Math.max (acum, val.count), 0) || 1) * 100 : 0} 
												size="small" 
												// trailColor="url(/bar.jpg)"
												// strokeLinecap={"round"}
												// strokeColor={{ '0%': '#00F50A', '100%': '#56F1D7' }}
												// strokeColor={{ '30%': '#A466D1', '80%': '#7989D5', '100%': '#00B2EA' }}
												// strokeColor={{ '30%': '#A466D1', '100%': '#00B2EA' }}
												// strokeColor={{ '0%': '#00B2EA', '70%': '#A466D1' }}
												// strokeColor={'transparent'} 
												showInfo={false} />
									</Col>
								</Row>
							))}
				

					{/* .ant-progress-line {
						background: url(/bar_clear5.jpg);
						background-size: 100% 100%;	
						border-radius: 0;
					} */}
				<style>
                    {/* background: #A466D1 !important; */}
					{/* {`
						.ant-progress-inner {
							border-radius: 0;
                            background:none;
						}
						.ant-progress-bg {
							background-size: 100% 100%;	
							border-radius: 10px;
                            border-bottom: 10px solid #00000050
						}
						`} */}
					{`
						.ant-progress-inner {
							border-radius: 0;
						}
						.ant-progress-bg {
							background: url(/bar.jpg);
							background-size: 100% 100%;	
							border-radius: 4px;
						}
						`}
						{/* // display: flex;
						// justify-content: flex-end; */}
				</style>

		</SlideLayout>
	)
}

