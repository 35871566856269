
import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconMoreVertical } from "@sberdevices/plasma-icons";


import {
	ArrowUpOutlined,
	ArrowDownOutlined,
	// CaretDownFilled,
} from '@ant-design/icons';



// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '12px 12px 10px',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "rgb(111 206 129)" : "#cfd3dd",
  background: isDragging ? "#248276" : "#ffffff20",

  display: 'flex',
  alignItems: 'center',

//   background: '#cfd3dd',
  borderRadius: '10px',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
//   background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: '8px 0',
//   width: 250
});


export default function App2({items = [], lang = 'ru', onChange = ()=> {}}) {

    
    const [current_json, set_current_json] = useState(''); 
    const [iiiitems, set_iiiitems] = useState(items); 



    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          iiiitems,
          result.source.index,
          result.destination.index
        );
    
        // this.setState({
        //   items
        // });
        set_iiiitems( items )
        onChange( items )
      }

      useEffect(() => {
          if (current_json == JSON.stringify(items) ) {
            
          } else {
                set_current_json ( JSON.stringify(items) )
                set_iiiitems(
        
                        items.map(x=> ({
                            id: `item-${x.opt_id}`,
                            content: x.opt_title[lang],
                            item: x,
                        }))
                )
          }
      })

    //   return (
        //  JSON.stringify(items)
        //  JSON.stringify(iiiitems)
    //   )

    //   <>
    // {/* {JSON.stringify(items)} */}
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {iiiitems.map((_item, index) => (
                  <Draggable key={_item.id || index } draggableId={_item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                          <div style={{margin:'0 5px'}}>{index+1}.</div>
                          &nbsp;
                          <div style={{marginRight:'-15px', opacity:0.2}}><IconMoreVertical/></div>
                          <div style={{marginRight:'0', opacity:0.2}}><IconMoreVertical/></div>
                          &nbsp;
                          &nbsp;
                          
                          {/* {JSON.stringify(_item)} */}
                          {/* {item.content} */}
                          <div style={{width:"100%"}}>
                              {_item.item?.opt_title && _item.item?.opt_title[lang]}
                          </div>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <div style={{width:70, position:'relative'}}>
                              { index > 0 &&
                                <span onClick={(e)=> { 
                                  onDragEnd({
                                    source: { index: index },
                                    destination: { index: index > 0 ? index-1 : 0 }
                                  })
                                  e.stopPropagation();
                                }}><ArrowUpOutlined /></span>
                              }
                              &nbsp;
                              { index < iiiitems.length - 1 &&
                                <span style={{position:"absolute", right:0}} onClick={(e)=> { 
                                  onDragEnd({
                                    source: { index: index },
                                    destination: { index: index < iiiitems.length - 1 ? index+1 : iiiitems.length - 1 }
                                  })
                                  e.stopPropagation();
                                }}><ArrowDownOutlined /></span>
                              }
                          </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
      // </>
}

// export default class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     //   items: //getItems(5) 
//       items: props.items.map(x=> ({
//           id: `item-${x.opt_id}`,
//           content: x.opt_title[props.lang],
//           item: x,
//       }))
//     };
//     this.onDragEnd = this.onDragEnd.bind(this);
//   }

//   onDragEnd(result) {
//     // dropped outside the list
//     if (!result.destination) {
//       return;
//     }

//     const items = reorder(
//       this.state.items,
//       result.source.index,
//       result.destination.index
//     );

//     this.setState({
//       items
//     });

//     this.props.onChange( items )
//   }

//   // Normally you would want to split things out into separate components.
//   // But in this example everything is just done in one place for simplicity
//   render() {
//     return (
//       <DragDropContext onDragEnd={this.onDragEnd}>
//         <Droppable droppableId="droppable">
//           {(provided, snapshot) => (
//             <div
//               {...provided.droppableProps}
//               ref={provided.innerRef}
//               style={getListStyle(snapshot.isDraggingOver)}
//             >
//               {this.state.items.map((item, index) => (
//                 <Draggable key={item.id} draggableId={item.id} index={index}>
//                   {(provided, snapshot) => (
//                     <div
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       style={getItemStyle(
//                         snapshot.isDragging,
//                         provided.draggableProps.style
//                       )}
//                     >
//                         <div style={{marginRight:'-15px', opacity:0.2}}><IconMoreVertical/></div>
//                         <div style={{marginRight:'0', opacity:0.2}}><IconMoreVertical/></div>
//                         &nbsp;
//                       {/* {item.content} */}
//                       {item.item.opt_title[this.props.lang]}
//                     </div>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </div>
//           )}
//         </Droppable>
//       </DragDropContext>
//     );
//   }
// }

// Put the thing into the DOM!
// ReactDOM.render(<App />, document.getElementById("root"));
