import SlideLayout from "../../elems/SlideLayout";
import VotePresQR from "../../elems/VotePresQR";

export default function PromoSlide({votecode ='5555', current_slide = {}}) {
	return (
		<SlideLayout topcenter={' '}>
			{/* VOX */}
			{/* <img style={{maxWidth:'700px', margin:'20vh auto 0'}} src="/sbervox_logo.png" /> */}
			<div style={{textAlign: 'center'}}>
				{/* <img style={{maxWidth:'700px', margin:'30vh auto 0'}} src="/sbervox2_logo.png" /> */}
				{/* <img style={{width:'100%', maxWidth:'700px', margin:'25vh auto 0'}} src="/sbervox_outline.svg" /> */}
				<img style={{width:'100%', maxWidth:'700px', margin:'20vh auto 0'}} src="/sbervox_outline.svg" />

				
				<div style={{width:'30vh', margin: '40px auto'}}>
					{/* <VotePresQR votecode={current_slide.pres_votecode} />				 */}
					<VotePresQR votecode={votecode} />	
					{/* {votecode}			 */}
				</div>
			</div>
			{/* <img style={{maxWidth:'700px', margin:'40vh auto 0'}} src="/sbervox_white.png" /> */}
		</SlideLayout>
	)
}
