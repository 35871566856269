import { Col, Row } from "antd";
import React from "react";
// import QRCode from "qrcode.react";
import SlideLayout from "../../elems/SlideLayout";
import VotePresQR from "../../elems/VotePresQR";


function options_size(current_slide) {
	return (current_slide?.choice_options || []).length > 6
}


export default function IntroVoteSlide({votecode, current_slide}) {

    // console.log(111111)
    // console.log(React.createElement('div', null, `Привет, ${current_slide.slide_id}`))
    // console.log(React.createElement('QRCode', {value:"http://sbervox.vispstudio.ru/#/v/5555", style:{width:'100%'}}, null))
	return (
		<SlideLayout>
            {/* <br /> */}
            {/* <br /> */}
            {/* <img style={{maxWidth:'200px', margin:'10px auto 20px'}} src="/sbervox_logo.png" /> */}
            {/* <img style={{maxWidth:'180px', margin:'30px auto 30px'}} src="/sbervox2_logo.png" /> */}
            {/* <img style={{maxWidth:'180px', margin:'30px auto 30px'}} src="/sbervox_white.png" /> */}
                        
            <Row gutter={30}>
                <Col span="2">
                </Col>
                {/* <Col span="20" style={{textAlign:'left', paddingTop:'15px'}}> */}
                <Col span="14" style={{textAlign:'left', paddingTop:'15px'}}>
                  
                    {(current_slide?.choice_options || [])
                        .sort((a,b)=>(a.opt_id - b.opt_id))
                        .map((x,i)=> (
                            <div key={i} style={{display:'flex'}}>
                                {/* <img src="/mark.jpg" style={{marginRight:'30px', marginTop:'35px'}} width="30" height="30" /> */}
                                {/* <div style={{marginRight:'30px', marginTop:'35px', width:30, height:30, background: '#A466D1', borderRadius: '10px',borderBottom: '10px solid #00000050'}}></div> */}
                                {/* <div style={{marginRight:'30px', marginTop: options_size(current_slide) ? '25px' : '45px', width:30, height:30, background: '#0bd818', borderRadius: '10px',borderBottom: '10px solid #00000050'}}></div> */}
                                <div style={{marginRight:'30px', marginTop: options_size(current_slide) ? '18px' : '45px', width:30, minWidth:30, height:30, background: '#0bd818', borderRadius: '10px',borderBottom: '10px solid #00000050'}}></div>
                                {/* <h2 key={x.opt_id} style={{lineHeight:1.2, margin: options_size(current_slide) ? '20px 0 0' : '25px 0 0', fontSize: options_size(current_slide) ? '34px' : '60px'}}> */}
                                <h2 key={x.opt_id} style={{lineHeight:1.2, margin: options_size(current_slide) ? '15px 0 0' : '25px 0 0', fontSize: options_size(current_slide) ? '30px' : '60px'}}>
                                    {x.opt_title['ru']} 
                                    {x.opt_title['en'] ? <><div className="en" style={{fontSize: options_size(current_slide) ? '20px' : '27px'}}>{x.opt_title['en']}</div></> : '' }
                                </h2>
                            </div>
                    ))}

                    <br />
                </Col>
                {/* <Col span="2"> */}
                <Col span="7" style={{ paddingTop:'45px'}}>
                    {/* <img width="100%" src="/qr-code3.png" style={{marginTop:'25px'}} /> */}
                    {/* <img width="100%" src="/qr-code4.png" style={{marginTop:'25px'}} /> */}

                    <VotePresQR votecode={current_slide.pres_votecode} />



                    {/* <QRCode value="http://sbervox.vispstudio.ru/#/v/5555" width="100" level="L" viewBox="0 0 256 256"/> */}
                    
                    {/* <QRCode value="http://sbervox.vispstudio.ru/#/v/5555" width={100} level="M" viewBox="0 0 256 256"/> */}

                    {/* <QRCode value="http://sbervox.vispstudio.ru/#/v/5555" width="100" level="Q" viewBox="0 0 256 256"/> */}
                    {/* <QRCode value="http://sbervox.vispstudio.ru/#/v/5555" width="100" level="H" viewBox="0 0 256 256"/> */}
                </Col>
            </Row>
		</SlideLayout>
	)
}